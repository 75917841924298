import { MastersActionTypes } from "./actionType";


const initialState = {
    isLoading: false,
    loading: false,
    items: [],
    errors: {},
    insertOrUpdateCountryGroup: {},
    getAllCountryGroup: {},
    getCountryGroupById: {},
    deleteCountryGroup: {},
    getAllCountryList: {},
    insertOrUpdateCountry: {},
    deleteCountry: {},
    getCountryGroupDropdown: {},
    getAllVehicleType: {},
    insertOrUpdateVehicleType: null,
    deleteVehicleType: {},
    getAllVehicleGroup: {},
    getVehicleGroupDropdown: {},
    insertOrUpdateVehicleGroup: {},
    deleteVehicleGroup: {},
    getAllCodeGroup: {},
    insertOrUpdateCodeGroup: {},
    deleteCodeGroup: {},
    getAllVehicleCategory: {},
    insertOrUpdateVehicleCategory: {},
    deleteVehicleCategory: {},
    getAllOptions: {},
    insertOrUpdateOptions: {},
    deleteOptions: {},
    getAllVehicleModel: {},
    getTonnageByVehicleCategoryDropdown: {},
    getCategoriesByTonnageDropdown: {},
    getVehicleGroupByCategoryDropdown: {},
    insertOrUpdateVehicleModel: {},
    deleteVehicleModel: {},
    getTargetMatrix: {},
    getAllVehicleModelDropdown: {},
    VehicleModelByVehicleGroupDropdown: {},
    getAllTonnage: {},
    getAllVehicleCategoryDropDown: {},
    getVehicleGroupByVehicleCategory: {},
    insertOrUpdateTonnage: {},
    insertOrUpdateTargrtMatrix: {},
    deleteTargetMatrix: {},
    deleteTonnage: {},
    getAllPackageMaster: {},
    getAllPackageSubitem: {},
    getAllPackageMasterDropdown: {},
    getVehicleGroupByPackage: {},
    insertOrUpdatePackageSubitem: {},
    deletePackageSubitem: {},
    geyAllCode: {},
    getAllCodeGroupDropdown: {},
    insertOrUpdateCode: {},
    deleteCode: {},
    vehicleCountryGroupMapping: {},
    insertOrUpdateVehicleCountryGroupMapping: {},
    deleteVehicleCountryGroupMapping: {},
    insertOrUpdatePackageMaster: {},
    deletePackageMaster: {},
    getAllVehicleTypeDropdown: {},
    getAllCodeRequest: {},
    insertOrUpdateCodeRequest: {},
    rejectCodeRequest: {},
    getCodeGroupByVehicleGroup: {}
}

const MasterReducer = (state = initialState, action) => {    
    const response = action.data;
    switch (action.type) {
        case MastersActionTypes.INIT_GET_MASTERS_DETAILS_MODULE:
            return {
                ...state, loading: false, isLoading: false, items: [], insertOrUpdateCountryGroup: {}, getAllCountryGroup: {}, getCountryGroupById: {}, deleteCountryGroup: {}, getAllCountryList: {}, insertOrUpdateCountry: {}, deleteCountry: {}, getCountryGroupDropdown: {}, getAllVehicleType: {}, insertOrUpdateVehicleType: {}, deleteVehicleType: {}, getAllVehicleGroup: {}, getVehicleGroupDropdown: {}, insertOrUpdateVehicleGroup: {}, deleteVehicleGroup: {}, getAllCodeGroup: {}, insertOrUpdateCodeGroup: {}, deleteCodeGroup: {}, getAllVehicleCategory: {}, insertOrUpdateVehicleCategory: {}, deleteVehicleCategory: {}, getAllOptions: {}, insertOrUpdateOptions: {}, deleteOptions: {}, getAllVehicleModel: {}, getTonnageByVehicleCategoryDropdown: {}, getCategoriesByTonnageDropdown: {}, getVehicleGroupByCategoryDropdown: {}, insertOrUpdateVehicleModel: {}, deleteVehicleModel: {}, getTargetMatrix: {}, getAllVehicleModelDropdown: {}, VehicleModelByVehicleGroupDropdown: {}, insertOrUpdateTargrtMatrix: {}, deleteTargetMatrix: {}, deleteTonnage: {}, getAllPackageMaster: {}, getAllPackageSubitem: {}, getAllPackageMasterDropdown: {}, getVehicleGroupByPackage: {}, insertOrUpdatePackageSubitem: {}, deletePackageSubitem: {}, geyAllCode: {}, getAllCodeGroupDropdown: {}, insertOrUpdateCode: {}, deleteCode: {}, insertOrUpdatePackageMaster: {}, deletePackageMaster: {}, vehicleCountryGroupMapping: {}, insertOrUpdateVehicleCountryGroupMapping: {}, deleteVehicleCountryGroupMapping: {}, getAllVehicleTypeDropdown: {}, getAllCodeRequest: {}, insertOrUpdateCodeRequest: {}, rejectCodeRequest: {}, getCodeGroupByVehicleGroup: {}

            }
        case MastersActionTypes.POST_COUNTRYGROUP_INSERT_OR_UPDATE_REQUEST:
            return { ...state, loading: true, insertOrUpdateCountryGroup: {} }
        case MastersActionTypes.POST_COUNTRYGROUP_INSERT_OR_UPDATE_SUCCESS:
            return { ...state, loading: false, insertOrUpdateCountryGroup: response }
        case MastersActionTypes.POST_COUNTRYGROUP_INSERT_OR_UPDATE_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_COUNTRYGROUP_REQUEST:
            return { ...state, loading: true, getAllCountryGroup: {} }
        case MastersActionTypes.GET_ALL_COUNTRYGROUP_SUCCESS:
            return { ...state, loading: false, getAllCountryGroup: response.data }
        case MastersActionTypes.GET_ALL_COUNTRYGROUP_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_COUNTRYGROUP_BY_ID_REQUEST:
            return { ...state, loading: true, getCountryGroupById: {} }
        case MastersActionTypes.GET_COUNTRYGROUP_BY_ID_SUCCESS:
            return { ...state, loading: false, getCountryGroupById: response.data }
        case MastersActionTypes.GET_COUNTRYGROUP_BY_ID_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_DELETE_COUNTRYGROUP_REQUEST:
            return { ...state, loading: true, deleteCountryGroup: {} }
        case MastersActionTypes.GET_DELETE_COUNTRYGROUP_SUCCESS:
            return { ...state, loading: false, deleteCountryGroup: response.data }
        case MastersActionTypes.GET_DELETE_COUNTRYGROUP_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_COUNTRY_REQUEST:
            return { ...state, loading: true, getAllCountryList: {} }
        case MastersActionTypes.GET_ALL_COUNTRY_SUCCESS:
            return { ...state, loading: false, getAllCountryList: response.data }
        case MastersActionTypes.GET_ALL_COUNTRY_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.INSERT_OR_UPDATE_COUNTRY_REQUEST:
            return { ...state, loading: true, insertOrUpdateCountry: {} }
        case MastersActionTypes.INSERT_OR_UPDATE_COUNTRY_SUCCESS:
            return { ...state, loading: false, insertOrUpdateCountry: response }
        case MastersActionTypes.INSERT_OR_UPDATE_COUNTRY_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.DELETE_COUNTRY_REQUEST:
            return { ...state, loading: true, deleteCountry: {} }
        case MastersActionTypes.DELETE_COUNTRY_SUCCESS:
            return { ...state, loading: false, deleteCountry: response?.payload }
        case MastersActionTypes.DELETE_COUNTRY_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_COUNTRYGROUP_DROPDOWN_REQUEST:
            return { ...state, loading: true, getCountryGroupDropdown: {} }
        case MastersActionTypes.GET_COUNTRYGROUP_DROPDOWN_SUCCESS:
            return { ...state, loading: false, getCountryGroupDropdown: response?.payload }
        case MastersActionTypes.GET_COUNTRYGROUP_DROPDOWN_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_VEHICLETYPE_REQUEST:
            return { ...state, loading: true, getAllVehicleType: {} }
        case MastersActionTypes.GET_ALL_VEHICLETYPE_SUCCESS:
            return { ...state, loading: false, getAllVehicleType: response.data }
        case MastersActionTypes.GET_ALL_VEHICLETYPE_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.INSERT_OR_UPDATE_VEHICLETYPE_REQUEST:
            return { ...state, loading: true, insertOrUpdateVehicleType: {} }
        case MastersActionTypes.INSERT_OR_UPDATE_VEHICLETYPE_SUCCESS:
            return { ...state, loading: false, insertOrUpdateVehicleType: response?.payload }
        case MastersActionTypes.INSERT_OR_UPDATE_VEHICLETYPE_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.DELETE_VEHICLE_TYPE_REQUEST:
            return { ...state, loading: true, deleteVehicleType: {} }
        case MastersActionTypes.DELETE_VEHICLE_TYPE_SUCCESS:
            return { ...state, loading: false, deleteVehicleType: response?.payload }
        case MastersActionTypes.DELETE_VEHICLE_TYPE_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_VEHICLEGROUP_REQUEST:
            return { ...state, loading: true, getAllVehicleGroup: {} }
        case MastersActionTypes.GET_ALL_VEHICLEGROUP_SUCCESS:
            return { ...state, loading: false, getAllVehicleGroup: response.data }
        case MastersActionTypes.GET_ALL_VEHICLEGROUP_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_All_VEHICLEGROUP_DROPDOWN_REQUEST:
            return { ...state, loading: true, getVehicleGroupDropdown: {} }
        case MastersActionTypes.GET_All_VEHICLEGROUP_DROPDOWN_SUCCESS:
            return { ...state, loading: false, getVehicleGroupDropdown: response?.payload }
        case MastersActionTypes.GET_All_VEHICLEGROUP_DROPDOWN_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.INSERT_OR_UPDATE_VEHICLEGROUP_REQUEST:
            return { ...state, loading: true, insertOrUpdateVehicleGroup: {} }
        case MastersActionTypes.INSERT_OR_UPDATE_VEHICLEGROUP_SUCCESS:
            return { ...state, loading: false, insertOrUpdateVehicleGroup: response?.payload }
        case MastersActionTypes.INSERT_OR_UPDATE_VEHICLEGROUP_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.DELETE_VEHICLE_GROUP_REQUEST:
            return { ...state, loading: true, deleteVehicleGroup: {} }
        case MastersActionTypes.DELETE_VEHICLE_GROUP_SUCCESS:
            return { ...state, loading: false, deleteVehicleGroup: response?.payload }
        case MastersActionTypes.DELETE_VEHICLE_GROUP_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_CODEGROUP_REQUEST:
            return { ...state, loading: true, getAllCodeGroup: {} }
        case MastersActionTypes.GET_ALL_CODEGROUP_SUCCESS:
            return { ...state, loading: false, getAllCodeGroup: response.data }
        case MastersActionTypes.GET_ALL_CODEGROUP_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.INSERT_OR_UPDATE_CODE_GROUP_REQUEST:
            return { ...state, loading: true, insertOrUpdateCodeGroup: {} }
        case MastersActionTypes.INSERT_OR_UPDATE_CODE_GROUP_SUCCESS:
            return { ...state, loading: false, insertOrUpdateCodeGroup: response?.payload }
        case MastersActionTypes.INSERT_OR_UPDATE_CODE_GROUP_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.DELETE_CODE_GROUP_REQUEST:
            return { ...state, loading: true, deleteCodeGroup: {} }
        case MastersActionTypes.DELETE_CODE_GROUP_SUCCESS:
            return { ...state, loading: false, deleteCodeGroup: response.data }
        case MastersActionTypes.DELETE_CODE_GROUP_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_VEHICLE_CATEGORT_REQUEST:
            return { ...state, loading: true, getAllVehicleCategory: {} }
        case MastersActionTypes.GET_ALL_VEHICLE_CATEGORT_SUCCESS:
            return { ...state, loading: false, getAllVehicleCategory: response.data }
        case MastersActionTypes.GET_ALL_VEHICLE_CATEGORT_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_CATEGORY_REQUEST:
            return { ...state, loading: true, insertOrUpdateVehicleCategory: {} }
        case MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_CATEGORY_SUCCESS:
            return { ...state, loading: false, insertOrUpdateVehicleCategory: response }
        case MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_CATEGORY_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.DELETE_VEHICLE_CATEGORY_REQUEST:
            return { ...state, loading: true, deleteVehicleCategory: {} }
        case MastersActionTypes.DELETE_VEHICLE_CATEGORY_SUCCESS:
            return { ...state, loading: false, deleteVehicleCategory: response.data }
        case MastersActionTypes.DELETE_VEHICLE_CATEGORY_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_OPTIONS_REQUEST:
            return { ...state, loading: true, getAllOptions: {} }
        case MastersActionTypes.GET_ALL_OPTIONS_SUCCESS:
            return { ...state, loading: false, getAllOptions: response.data }
        case MastersActionTypes.GET_ALL_OPTIONS_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.INSERT_OR_UPDATE_OPTIONS_REQUEST:
            return { ...state, loading: true, insertOrUpdateOptions: {} }
        case MastersActionTypes.INSERT_OR_UPDATE_OPTIONS_SUCCESS:
            return { ...state, loading: false, insertOrUpdateOptions: response }
        case MastersActionTypes.INSERT_OR_UPDATE_OPTIONS_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.DELETE_OPTIONS_REQUEST:
            return { ...state, loading: true, deleteOptions: {} }
        case MastersActionTypes.DELETE_OPTIONS_SUCCESS:
            return { ...state, loading: false, deleteOptions: response.data }
        case MastersActionTypes.DELETE_OPTIONS_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_VEHICLE_MODEL_REQUEST:
            return { ...state, loading: true, getAllVehicleModel: {} }
        case MastersActionTypes.GET_ALL_VEHICLE_MODEL_SUCCESS:
            return { ...state, loading: false, getAllVehicleModel: response.data }
        case MastersActionTypes.GET_ALL_VEHICLE_MODEL_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_TONNAGE_BY_VEHICLE_CATEGORY_DROPDOWN_REQUEST:
            return { ...state, loading: true, getTonnageByVehicleCategoryDropdown: {} }
        case MastersActionTypes.GET_TONNAGE_BY_VEHICLE_CATEGORY_DROPDOWN_SUCCESS:
            return { ...state, loading: false, getTonnageByVehicleCategoryDropdown: response?.payload }
        case MastersActionTypes.GET_TONNAGE_BY_VEHICLE_CATEGORY_DROPDOWN_FAILED:
            return { ...state, loading: false, errors: action.error }

        // case MastersActionTypes.GET_All_CATEGORIES_BY_TONNAGE_DROPDOWN_REQUEST:
        //     return { ...state, loading: true, getCategoriesByTonnageDropdown: {} }
        // case MastersActionTypes.GET_All_CATEGORIES_BY_TONNAGE_DROPDOWN_SUCCESS:
        //     return { ...state, loading: false, getCategoriesByTonnageDropdown: response?.payload }
        // case MastersActionTypes.GET_All_CATEGORIES_BY_TONNAGE_DROPDOWN_FAILED:
        //     return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_All_CATEGORIES_BY_VEHICLEGROUP_DROPDOWN_REQUEST:
            return { ...state, loading: true, getCategoriesByVehicleGroupDropdown: {} }
        case MastersActionTypes.GET_All_CATEGORIES_BY_VEHICLEGROUP_DROPDOWN_SUCCESS:
            return { ...state, loading: false, getCategoriesByVehicleGroupDropdown: response?.payload }
        case MastersActionTypes.GET_All_CATEGORIES_BY_VEHICLEGROUP_DROPDOWN_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_All_VEHICLE_GROUP_BY_CATEGORY_DROPDOWN_REQUEST:
            return { ...state, loading: true, getVehicleGroupByCategoryDropdown: {} }
        case MastersActionTypes.GET_All_VEHICLE_GROUP_BY_CATEGORY_DROPDOWN_SUCCESS:
            return { ...state, loading: false, getVehicleGroupByCategoryDropdown: response?.payload }
        case MastersActionTypes.GET_All_VEHICLE_GROUP_BY_CATEGORY_DROPDOWN_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_MODEL_REQUEST:
            return { ...state, loading: true, insertOrUpdateVehicleModel: {} }
        case MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_MODEL_SUCCESS:
            return { ...state, loading: false, insertOrUpdateVehicleModel: response }
        case MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_MODEL_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.DELETE_VEHICLE_MODEL_REQUEST:
            return { ...state, loading: true, deleteVehicleModel: {} }
        case MastersActionTypes.DELETE_VEHICLE_MODEL_SUCCESS:
            return { ...state, loading: false, deleteVehicleModel: response.data }
        case MastersActionTypes.DELETE_VEHICLE_MODEL_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_TARGET_MATRIX_REQUEST:
            return { ...state, loading: true, getTargetMatrix: {} }
        case MastersActionTypes.GET_ALL_TARGET_MATRIX_SUCCESS:
            return { ...state, loading: false, getTargetMatrix: response.data }
        case MastersActionTypes.GET_ALL_TARGET_MATRIX_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_All_VEHICLE_MODEL_DROPDOWN_REQUEST:
            return { ...state, loading: true, getAllVehicleModelDropdown: {} }
        case MastersActionTypes.GET_All_VEHICLE_MODEL_DROPDOWN_SUCCESS:
            return { ...state, loading: false, getAllVehicleModelDropdown: response?.payload }
        case MastersActionTypes.GET_All_VEHICLE_MODEL_DROPDOWN_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_VEHICLE_MODEL_BY_VEHICLE_GROUP_REQUEST:
            return { ...state, loading: true, VehicleModelByVehicleGroupDropdown: {} }
        case MastersActionTypes.GET_VEHICLE_MODEL_BY_VEHICLE_GROUP_SUCCESS:
            return { ...state, loading: false, VehicleModelByVehicleGroupDropdown: response?.payload }
        case MastersActionTypes.GET_VEHICLE_MODEL_BY_VEHICLE_GROUP_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_TONNAGE_REQUEST:
            return { ...state, loading: true, getAllTonnage: {} }
        case MastersActionTypes.GET_ALL_TONNAGE_SUCCESS:
            return { ...state, loading: false, getAllTonnage: response.data }
        case MastersActionTypes.GET_ALL_TONNAGE_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_VEHICLE_CATEGORY_DROPDOWN_REQUEST:
            return { ...state, loading: true, getAllVehicleCategoryDropDown: {} }
        case MastersActionTypes.GET_ALL_VEHICLE_CATEGORY_DROPDOWN_SUCCESS:
            return { ...state, loading: false, getAllVehicleCategoryDropDown: response }
        case MastersActionTypes.GET_ALL_VEHICLE_CATEGORY_DROPDOWN_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_VEHICLE_GROUP_BY_VEHICLE_CATEGORY_REQUEST:
            return { ...state, loading: true, getVehicleGroupByVehicleCategory: {} }
        case MastersActionTypes.GET_VEHICLE_GROUP_BY_VEHICLE_CATEGORY_SUCCESS:
            return { ...state, loading: false, getVehicleGroupByVehicleCategory: response }
        case MastersActionTypes.GET_VEHICLE_GROUP_BY_VEHICLE_CATEGORY_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.INSERT_OR_UPDATE_TONNAGE_REQUEST:
            return { ...state, loading: true, insertOrUpdateTonnage: {} }
        case MastersActionTypes.INSERT_OR_UPDATE_TONNAGE_SUCCESS:
            return { ...state, loading: false, insertOrUpdateTonnage: response }
        case MastersActionTypes.INSERT_OR_UPDATE_TONNAGE_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.INSERT_OR_UPDATE_TARGET_MATRIX_REQUEST:
            return { ...state, loading: true, insertOrUpdateTargrtMatrix: {} }
        case MastersActionTypes.INSERT_OR_UPDATE_TARGET_MATRIX_SUCCESS:
            return { ...state, loading: false, insertOrUpdateTargrtMatrix: response?.payload }
        case MastersActionTypes.INSERT_OR_UPDATE_TARGET_MATRIX_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.DELETE_TARGET_MATRIX_REQUEST:
            return { ...state, loading: true, deleteTargetMatrix: {} }
        case MastersActionTypes.DELETE_TARGET_MATRIX_SUCCESS:
            return { ...state, loading: false, deleteTargetMatrix: response.data }
        case MastersActionTypes.DELETE_TARGET_MATRIX_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.DELETE_TONNAGE_REQUEST:
            return { ...state, loading: true, deleteTonnage: {} }
        case MastersActionTypes.DELETE_TONNAGE_SUCCESS:
            return { ...state, loading: false, deleteTonnage: response.data }
        case MastersActionTypes.DELETE_TONNAGE_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_PACKAGE_MASTER_REQUEST:
            return { ...state, loading: true, getAllPackageMaster: {} }
        case MastersActionTypes.GET_ALL_PACKAGE_MASTER_SUCCESS:
            return { ...state, loading: false, getAllPackageMaster: response.data }
        case MastersActionTypes.GET_ALL_PACKAGE_MASTER_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_PACKAGE_SUBITEM_REQUEST:
            return { ...state, loading: true, getAllPackageSubitem: {} }
        case MastersActionTypes.GET_ALL_PACKAGE_SUBITEM_SUCCESS:
            return { ...state, loading: false, getAllPackageSubitem: response.data }
        case MastersActionTypes.GET_ALL_PACKAGE_SUBITEM_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_PACKAGE_MASTER_DROPDOWN_REQUEST:
            return { ...state, loading: true, getAllPackageMasterDropdown: {} }
        case MastersActionTypes.GET_ALL_PACKAGE_MASTER_DROPDOWN_SUCCESS:
            return { ...state, loading: false, getAllPackageMasterDropdown: response }
        case MastersActionTypes.GET_ALL_PACKAGE_MASTER_DROPDOWN_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_VEHICLE_GROUP_BY_PACKAGE_REQUEST:
            return { ...state, loading: true, getVehicleGroupByPackage: {} }
        case MastersActionTypes.GET_VEHICLE_GROUP_BY_PACKAGE_SUCCESS:
            return { ...state, loading: false, getVehicleGroupByPackage: response }
        case MastersActionTypes.GET_VEHICLE_GROUP_BY_PACKAGE_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_SUBITEM_REQUEST:
            return { ...state, loading: true, insertOrUpdatePackageSubitem: {} }
        case MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_SUBITEM_SUCCESS:
            return { ...state, loading: false, insertOrUpdatePackageSubitem: response?.payload }
        case MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_SUBITEM_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.DELETE_PACTAGE_SUBITEM_REQUEST:
            return { ...state, loading: true, deletePackageSubitem: {} }
        case MastersActionTypes.DELETE_PACTAGE_SUBITEM_SUCCESS:
            return { ...state, loading: false, deletePackageSubitem: response.data }
        case MastersActionTypes.DELETE_PACTAGE_SUBITEM_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_CODE_REQUEST:
            return { ...state, loading: true, geyAllCode: {} }
        case MastersActionTypes.GET_ALL_CODE_SUCCESS:
            return { ...state, loading: false, geyAllCode: response.data }
        case MastersActionTypes.GET_ALL_CODE_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_All_CODE_GROUP_DROPDOWN_REQUEST:
            return { ...state, loading: true, getAllCodeGroupDropdown: {} }
        case MastersActionTypes.GET_All_CODE_GROUP_DROPDOWN_SUCCESS:
            return { ...state, loading: false, getAllCodeGroupDropdown: response }
        case MastersActionTypes.GET_All_CODE_GROUP_DROPDOWN_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.INSERT_OR_UPDATE_CODE_REQUEST:
            return { ...state, loading: true, insertOrUpdateCode: {} }
        case MastersActionTypes.INSERT_OR_UPDATE_CODE_SUCCESS:
            return { ...state, loading: false, insertOrUpdateCode: response?.payload }
        case MastersActionTypes.INSERT_OR_UPDATE_CODE_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.DELETE_CODE_REQUEST:
            return { ...state, loading: true, deleteCode: {} }
        case MastersActionTypes.DELETE_CODE_SUCCESS:
            return { ...state, loading: false, deleteCode: response?.payload }
        case MastersActionTypes.DELETE_CODE_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_VEHICLE_COUNTRYGROUP_MAPPING_REQUEST:
            return { ...state, loading: true, vehicleCountryGroupMapping: {} }
        case MastersActionTypes.GET_ALL_VEHICLE_COUNTRYGROUP_MAPPING_SUCCESS:
            return { ...state, loading: false, vehicleCountryGroupMapping: response.data }
        case MastersActionTypes.GET_ALL_VEHICLE_COUNTRYGROUP_MAPPING_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_COUNTRYGROUP_MAPPING_REQUEST:
            return { ...state, loading: true, insertOrUpdateVehicleCountryGroupMapping: {} }
        case MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_COUNTRYGROUP_MAPPING_SUCCESS:
            return { ...state, loading: false, insertOrUpdateVehicleCountryGroupMapping: response?.payload }
        case MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_COUNTRYGROUP_MAPPING_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.DELETE_VEHICLE_COUNTRYGROUP_MAPPING_REQUEST:
            return { ...state, loading: true, deleteVehicleCountryGroupMapping: {} }
        case MastersActionTypes.DELETE_VEHICLE_COUNTRYGROUP_MAPPING_SUCCESS:
            return { ...state, loading: false, deleteVehicleCountryGroupMapping: response.data }
        case MastersActionTypes.DELETE_VEHICLE_COUNTRYGROUP_MAPPING_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_MASTER_REQUEST:
            return { ...state, loading: true, insertOrUpdatePackageMaster: {} }
        case MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_MASTER_SUCCESS:
            return { ...state, loading: false, insertOrUpdatePackageMaster: response?.payload }
        case MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_MASTER_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.DELETE_PACKAGE_MASTER_REQUEST:
            return { ...state, loading: true, deletePackageMaster: {} }
        case MastersActionTypes.DELETE_PACKAGE_MASTER_SUCCESS:
            return { ...state, loading: false, deletePackageMaster: response?.payload }
        case MastersActionTypes.DELETE_PACKAGE_MASTER_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_MASTER_REQUEST:
            return { ...state, loading: true, insertOrUpdatePackageMaster: {} }
        case MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_MASTER_SUCCESS:
            return { ...state, loading: false, insertOrUpdatePackageMaster: response?.payload }
        case MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_MASTER_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.DELETE_PACKAGE_MASTER_REQUEST:
            return { ...state, loading: true, deletePackageMaster: {} }
        case MastersActionTypes.DELETE_PACKAGE_MASTER_SUCCESS:
            return { ...state, loading: false, deletePackageMaster: response?.payload }
        case MastersActionTypes.DELETE_PACKAGE_MASTER_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_VEHICLE_TYPE_DROPDOWN_REQUEST:
            return { ...state, loading: true, getAllVehicleTypeDropdown: {} }
        case MastersActionTypes.GET_ALL_VEHICLE_TYPE_DROPDOWN_SUCCESS:
            return { ...state, loading: false, getAllVehicleTypeDropdown: response }
        case MastersActionTypes.GET_ALL_VEHICLE_TYPE_DROPDOWN_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_ALL_CODE_REQUEST_REQUEST:
            return { ...state, loading: true, getAllCodeRequest: {} }
        case MastersActionTypes.GET_ALL_CODE_REQUEST_SUCCESS:
            return { ...state, loading: false, getAllCodeRequest: response.data }
        case MastersActionTypes.GET_ALL_CODE_REQUEST_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.INSERT_OR_UPDATE_CODE_REQUEST_REQUEST:
            return { ...state, loading: true, insertOrUpdateCodeRequest: {} }
        case MastersActionTypes.INSERT_OR_UPDATE_CODE_REQUEST_SUCCESS:
            return { ...state, loading: false, insertOrUpdateCodeRequest: response?.payload }
        case MastersActionTypes.INSERT_OR_UPDATE_CODE_REQUEST_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.REJECT_CODE_REQUEST_REQUEST:
            return { ...state, loading: true, rejectCodeRequest: {} }
        case MastersActionTypes.REJECT_CODE_REQUEST_SUCCESS:
            return { ...state, loading: false, rejectCodeRequest: response?.payload }
        case MastersActionTypes.REJECT_CODE_REQUEST_FAILED:
            return { ...state, loading: false, errors: action.error }

        case MastersActionTypes.GET_CODE_GROUP_BY_VEHICLE_GROUP_REQUEST:
            return { ...state, loading: true, getCodeGroupByVehicleGroup: {} }
        case MastersActionTypes.GET_CODE_GROUP_BY_VEHICLE_GROUP_SUCCESS:
            return { ...state, loading: false, getCodeGroupByVehicleGroup: response.data }
        case MastersActionTypes.GET_CODE_GROUP_BY_VEHICLE_GROUP_FAILED:
            return { ...state, loading: false, errors: action.error }

        default: {
            return state;
        }
    }
}

export default MasterReducer;
