// // saga.ts
// import { all, call, fork, takeEvery, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
// import { Action } from 'redux';

// // Action types
// import {
//     CHANGE_LAYOUT,
//     CHANGE_SIDEBAR_THEME,
//     CHANGE_LAYOUT_MODE,
//     CHANGE_LAYOUT_WIDTH,
//     CHANGE_LAYOUT_POSITION,
//     CHANGE_TOPBAR_THEME,
//     CHANGE_SIDEBAR_SIZE_TYPE,
//     CHANGE_SIDEBAR_VIEW,
//     CHANGE_SIDEBAR_IMAGE_TYPE,
//     CHANGE_PRELOADER,
//     CHANGE_SIDEBAR_VISIBILITY
// } from './actionType';

// // Define action types
// interface ChangeLayoutAction extends Action<typeof CHANGE_LAYOUT> {
//     payload: string;
// }

// interface ChangeSidebarThemeAction extends Action<typeof CHANGE_SIDEBAR_THEME> {
//     payload: string;
// }

// interface ChangeLayoutModeAction extends Action<typeof CHANGE_LAYOUT_MODE> {
//     payload: string;
// }

// interface ChangeLayoutWidthAction extends Action<typeof CHANGE_LAYOUT_WIDTH> {
//     payload: 'lg' | 'md' | 'sm' | 'sm-hover';
// }

// interface ChangeLayoutPositionAction extends Action<typeof CHANGE_LAYOUT_POSITION> {
//     payload: string;
// }

// interface ChangeTopbarThemeAction extends Action<typeof CHANGE_TOPBAR_THEME> {
//     payload: string;
// }

// interface ChangeSidebarImageTypeAction extends Action<typeof CHANGE_SIDEBAR_IMAGE_TYPE> {
//     payload: string;
// }

// interface ChangePreloaderAction extends Action<typeof CHANGE_PRELOADER> {
//     payload: string;
// }

// interface ChangeSidebarVisibilityAction extends Action<typeof CHANGE_SIDEBAR_VISIBILITY> {
//     payload: string;
// }

// interface ChangeSidebarSizeTypeAction extends Action<typeof CHANGE_SIDEBAR_SIZE_TYPE> {
//     payload: 'lg' | 'md' | 'sm' | 'sm-hover';
// }

// interface ChangeSidebarViewTypeAction extends Action<typeof CHANGE_SIDEBAR_VIEW> {
//     payload: string;
// }

// type SagaEffects = CallEffect<any> | ForkEffect<any> | PutEffect<any>;

// /**
//  * Changes the body attribute
//  */
// function changeHTMLAttribute(attribute: string, value: string): boolean {
//     if (document.documentElement) document.documentElement.setAttribute(attribute, value);
//     return true;
// }

// /**
//  * Changes the layout type
//  */
// function* changeLayoutTheme(action: ChangeLayoutAction): Generator<SagaEffects> {
//     try {
//         const { payload: layout } = action;
//         if (layout === 'twocolumn') {
//             document.documentElement.removeAttribute('data-layout-width');
//         } else if (layout === 'horizontal') {
//             document.documentElement.removeAttribute('data-sidebar-size');
//         }
//         yield call(changeHTMLAttribute, 'data-layout', layout);
//     } catch (error) {
//         console.error(error);
//     }
// }

// /**
//  * Changes the layout mode
//  */
// function* changeLayoutMode(action: ChangeLayoutModeAction): Generator<SagaEffects> {
//     try {
//         yield call(changeHTMLAttribute, 'data-bs-theme', action.payload);
//     } catch (error) {
//         console.error(error);
//     }
// }

// /**
//  * Changes the left sidebar theme
//  */
// function* changeLeftSidebarTheme(action: ChangeSidebarThemeAction): Generator<SagaEffects> {
//     try {
//         yield call(changeHTMLAttribute, 'data-sidebar', action.payload);
//     } catch (error) {
//         console.error(error);
//     }
// }

// /**
//  * Changes the layout width
//  */
// function* changeLayoutWidth(action: ChangeLayoutWidthAction): Generator<SagaEffects> {
//     try {
//         if (action.payload === 'lg') {
//             yield call(changeHTMLAttribute, 'data-layout-width', 'fluid');
//         } else {
//             yield call(changeHTMLAttribute, 'data-layout-width', 'boxed');
//         }
//     } catch (error) {
//         console.error(error);
//     }
// }

// /**
//  * Changes the layout position
//  */
// function* changeLayoutPosition(action: ChangeLayoutPositionAction): Generator<SagaEffects> {
//     try {
//         yield call(changeHTMLAttribute, 'data-layout-position', action.payload);
//     } catch (error) {
//         console.error(error);
//     }
// }

// /**
//  * Changes the sidebar visibility
//  */
// function* changeSidebarVisibility(action: ChangeSidebarVisibilityAction): Generator<SagaEffects> {
//     try {
//         yield call(changeHTMLAttribute, 'data-sidebar-visibility', action.payload);
//     } catch (error) {
//         console.error(error);
//     }
// }

// /**
//  * Changes the topbar theme
//  */
// function* changeTopbarTheme(action: ChangeTopbarThemeAction): Generator<SagaEffects> {
//     try {
//         yield call(changeHTMLAttribute, 'data-topbar', action.payload);
//     } catch (error) {
//         console.error(error);
//     }
// }

// /**
//  * Changes the sidebar image type
//  */
// function* changeSidebarImageType(action: ChangeSidebarImageTypeAction): Generator<SagaEffects> {
//     try {
//         yield call(changeHTMLAttribute, 'data-sidebar-image', action.payload);
//     } catch (error) {
//         console.error(error);
//     }
// }

// /**
//  * Changes the preloader
//  */
// function* changePreloader(action: ChangePreloaderAction): Generator<SagaEffects> {
//     try {
//         yield call(changeHTMLAttribute, 'data-preloader', action.payload);
//     } catch (error) {
//         console.error(error);
//     }
// }

// /**
//  * Changes the sidebar size type
//  */
// function* changeLeftSidebarSizeType(action: ChangeSidebarSizeTypeAction): Generator<SagaEffects> {
//     try {
//         const sizeType = action.payload;
//         switch (sizeType) {
//             case 'lg':
//                 yield call(changeHTMLAttribute, 'data-sidebar-size', 'lg');
//                 break;
//             case 'md':
//                 yield call(changeHTMLAttribute, 'data-sidebar-size', 'md');
//                 break;
//             case 'sm':
//                 yield call(changeHTMLAttribute, 'data-sidebar-size', 'sm');
//                 break;
//             case 'sm-hover':
//                 yield call(changeHTMLAttribute, 'data-sidebar-size', 'sm-hover');
//                 break;
//             default:
//                 yield call(changeHTMLAttribute, 'data-sidebar-size', 'lg');
//         }
//     } catch (error) {
//         console.error(error);
//     }
// }

// /**
//  * Changes the sidebar view type
//  */
// function* changeLeftSidebarViewType(action: ChangeSidebarViewTypeAction): Generator<SagaEffects> {
//     try {
//         const { payload: viewType } = action;
//         if (document.documentElement.getAttribute('data-layout') !== 'semibox') {
//             yield call(changeHTMLAttribute, 'data-layout-style', viewType);
//         } else {
//             document.documentElement.removeAttribute('data-layout-style');
//         }
//     } catch (error) {
//         console.error(error);
//     }
// }

// /**
//  * Watchers
//  */
// export function* watchChangeLayoutType() {
//     yield takeEvery(CHANGE_LAYOUT, changeLayoutTheme);
// }

// export function* watchChangeLayoutMode() {
//     yield takeEvery(CHANGE_LAYOUT_MODE, changeLayoutMode);
// }

// export function* watchChangeLeftSidebarTheme() {
//     yield takeEvery(CHANGE_SIDEBAR_THEME, changeLeftSidebarTheme);
// }

// export function* watchChangeLayoutWidth() {
//     yield takeEvery(CHANGE_LAYOUT_WIDTH, changeLayoutWidth);
// }

// export function* watchChangeLayoutPosition() {
//     yield takeEvery(CHANGE_LAYOUT_POSITION, changeLayoutPosition);
// }

// export function* watchChangeTopbarTheme() {
//     yield takeEvery(CHANGE_TOPBAR_THEME, changeTopbarTheme);
// }

// export function* watchChangeLeftSidebarSizeType() {
//     yield takeEvery(CHANGE_SIDEBAR_SIZE_TYPE, changeLeftSidebarSizeType);
// }

// export function* watchChangeLeftSidebarViewType() {
//     yield takeEvery(CHANGE_SIDEBAR_VIEW, changeLeftSidebarViewType);
// }

// export function* watchChangeSidebarImageType() {
//     yield takeEvery(CHANGE_SIDEBAR_IMAGE_TYPE, changeSidebarImageType);
// }

// export function* watchChangePreloader() {
//     yield takeEvery(CHANGE_PRELOADER, changePreloader);
// }

// export function* watchChangeSidebarVisibility() {
//     yield takeEvery(CHANGE_SIDEBAR_VISIBILITY, changeSidebarVisibility);
// }

// function* LayoutSaga() {
//     yield all([
//         fork(watchChangeLayoutType),
//         fork(watchChangeLeftSidebarTheme),
//         fork(watchChangeLayoutMode),
//         fork(watchChangeLayoutWidth),
//         fork(watchChangeLayoutPosition),
//         fork(watchChangeTopbarTheme),
//         fork(watchChangeLeftSidebarSizeType),
//         fork(watchChangeLeftSidebarViewType),
//         fork(watchChangeSidebarImageType),
//         fork(watchChangePreloader),
//         fork(watchChangeSidebarVisibility)
//     ]);
// }

// export default LayoutSaga;

// @flow
import { all, call, fork, takeEvery } from "redux-saga/effects";

import {
    CHANGE_LAYOUT,
    CHANGE_SIDEBAR_THEME,
    CHANGE_LAYOUT_MODE,
    CHANGE_LAYOUT_WIDTH,
    CHANGE_LAYOUT_POSITION,
    CHANGE_TOPBAR_THEME,
    CHANGE_SIDEBAR_SIZE_TYPE,
    CHANGE_SIDEBAR_VIEW,
    CHANGE_SIDEBAR_IMAGE_TYPE,
    CHANGE_PRELOADER,
    CHANGE_SIDEBAR_VISIBILITY
} from './actionType';

/**
 * Changes the body attribute
 */
function changeHTMLAttribute(attribute, value) {
    if (document.documentElement) document.documentElement.setAttribute(attribute, value);
    return true;
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* changeLayoutTheme({ payload: layout }) {
    try {
        if (layout === "twocolumn") {
            document.documentElement.removeAttribute("data-layout-width");
        } else if (layout === "horizontal") {
            document.documentElement.removeAttribute("data-sidebar-size");
        }
        yield call(changeHTMLAttribute, "data-layout", layout);
    } catch (error) {
        // console.log(error);
    }
}


/**
 * Changes the layout mode
 * @param {*} param0
 */
function* changeLayoutMode({ payload: mode }) {
    try {
        yield call(changeHTMLAttribute, "data-bs-theme", mode);
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
function* changeLeftSidebarTheme({ payload: theme }) {   
    try {
        yield call(changeHTMLAttribute, "data-sidebar", theme);
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Changes the layout width
 * @param {*} param0
 */
function* changeLayoutWidth({ payload: layoutWidth }) {
    try {
        if (layoutWidth === 'lg') {
            yield call(changeHTMLAttribute, "data-layout-width", "fluid");
        } else {
            yield call(changeHTMLAttribute, "data-layout-width", "boxed");
        }
        // yield call(changeHTMLAttribute, "data-sidebar-size", layoutWidth);
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Changes the layout position
 * @param {*} param0
 */
function* changeLayoutPosition({ payload: layoutposition }) {
    try {
        yield call(changeHTMLAttribute, "data-layout-position", layoutposition);
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Changes the layout visiilty
 * @param {*} param0
 */
function* changeSidebarVisibility({ payload: visibility }) {
    try {
        yield call(changeHTMLAttribute, "data-sidebar-visibility", visibility);
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Changes the topbar themes
 * @param {*} param0
 */
function* changeTopbarTheme({ payload: topbarTheme }) {
    try {
        yield call(changeHTMLAttribute, "data-topbar", topbarTheme);
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Changes the topbar themes
 * @param {*} param0
 */
 function* changeSidebarImageType({ payload: leftsidebarImagetype }) {
    try {
        yield call(changeHTMLAttribute, "data-sidebar-image", leftsidebarImagetype);
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Changes the Preloader
 * @param {*} param0
 */
 function* changePreloader({ payload: preloaderTypes }) {
    try {
        yield call(changeHTMLAttribute, "data-preloader", preloaderTypes);
    } catch (error) {
        // console.log(error);
    }
}


/**
 * Changes the topbar themes
 * @param {*} param0
 */
function* changeLeftsidebarSizeType({ payload: leftsidebarSizetype }) {
    try {
        switch (leftsidebarSizetype) {
            case 'lg':
                yield call(changeHTMLAttribute, "data-sidebar-size", "lg");
                break;
            case 'md':
                yield call(changeHTMLAttribute, "data-sidebar-size", "md");
                break;
            case "sm":
                yield call(changeHTMLAttribute, "data-sidebar-size", "sm");
                break;
            case "sm-hover":
                yield call(changeHTMLAttribute, "data-sidebar-size", "sm-hover");
                break;
            default:
                yield call(changeHTMLAttribute, "data-sidebar-size", "lg");
        }
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Changes the topbar themes
 * @param {*} param0
 */
function* changeLeftSidebarViewType({ payload: leftsidebarViewtype }) {
    try {
        if(document.documentElement.getAttribute("data-layout") !== "semibox"){
            yield call(changeHTMLAttribute, "data-layout-style", leftsidebarViewtype);
        }else {
            document.documentElement.removeAttribute("data-layout-style");
        }
    } catch (error) {
        // console.log(error);
    }
}

/**
 * Watchers
 */
export function* watchChangeLayoutType() {
    // @ts-ignore
    yield takeEvery(CHANGE_LAYOUT, changeLayoutTheme);
}
export function* watchChangeLayoutMode() {
    // @ts-ignore
    yield takeEvery(CHANGE_LAYOUT_MODE, changeLayoutMode);
}
export function* watchChangeLeftSidebarTheme() {
    // @ts-ignore
    yield takeEvery(CHANGE_SIDEBAR_THEME, changeLeftSidebarTheme);
}
export function* watchChangeLayoutWidth() {
    // @ts-ignore
    yield takeEvery(CHANGE_LAYOUT_WIDTH, changeLayoutWidth);
}
export function* watchChangeLayoutPosition() {
    // @ts-ignore
    yield takeEvery(CHANGE_LAYOUT_POSITION, changeLayoutPosition);
}
export function* watchChangeTopbarTheme() {
    // @ts-ignore
    yield takeEvery(CHANGE_TOPBAR_THEME, changeTopbarTheme);
}
export function* watchChangeLeftsidebarSizeType() {
    // @ts-ignore
    yield takeEvery(CHANGE_SIDEBAR_SIZE_TYPE, changeLeftsidebarSizeType);
}
export function* watchChangeLeftSidebarViewType() {
    // @ts-ignore
    yield takeEvery(CHANGE_SIDEBAR_VIEW, changeLeftSidebarViewType);
}
export function* watchChangeSidebarImageType() {
    // @ts-ignore
    yield takeEvery(CHANGE_SIDEBAR_IMAGE_TYPE, changeSidebarImageType);
}
export function* watchchangePreloader() {
    // @ts-ignore
    yield takeEvery(CHANGE_PRELOADER, changePreloader);
}
export function* watchchangeSidebarVisibility() {
    // @ts-ignore
    yield takeEvery(CHANGE_SIDEBAR_VISIBILITY, changeSidebarVisibility);
}

function* LayoutSaga() {
    yield all([
        fork(watchChangeLayoutType),
        fork(watchChangeLeftSidebarTheme),
        fork(watchChangeLayoutMode),
        fork(watchChangeLayoutWidth),
        fork(watchChangeLayoutPosition),
        fork(watchChangeTopbarTheme),
        fork(watchChangeLeftsidebarSizeType),
        fork(watchChangeLeftSidebarViewType),
        fork(watchChangeSidebarImageType),
        fork(watchchangePreloader),
        fork(watchchangeSidebarVisibility)
    ]);
}

export default LayoutSaga;
