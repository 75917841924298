import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import "../CodeConfiguration/style.css";
import { connect } from "react-redux";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Label,
  Button,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  getSaveEditCodeList,
  getSubmitEditCodeList,
} from "../../store/actions";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

const EditCodeList = (props: any) => {
  document.title = "EVT-Code List";

  const history = useNavigate();
  const { creatorEditChangeTableData, loading, submitEditCodeList,saveEditCodeList } = props;
  const [tableData, setTableData] = useState<any>(null);
  const [originalTableData, setOriginalTableData] = useState<any>(null);
  const [dataList, setDataList] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);

  const dropdownOption = options?.map((item) => ({
    value: item.value,
    label: item.value,
    id: item.id,
  }));

  const staticHeader = [
    "Code Group",
    "Code",
    "Code Description",
    "Note",
    "Remarks",
    "CR",
    "CO_KEM",
  ];

  // Ensure countryGroupList is defined only if dataList is available
  const countryGroupList =
    dataList?.codesAndVehicleGroup?.[0]?.vehicleCountryGroup?.map(
      (data: any) => data?.countryGroupList
    ) || [];

  useEffect(() => {
    if (creatorEditChangeTableData) {
      setDataList(creatorEditChangeTableData);
      setOptions(creatorEditChangeTableData.options);
      console.log("creatorEditChangeTableData", creatorEditChangeTableData);
    }
  }, [creatorEditChangeTableData]);

  const handleSelectChange = (
    selectedOption: any,
    mainIndex: number,
    subIndex1: number,
    subIndex2: number
  ) => {
    setDataList((prevDataList: any) => {
      const updatedTableData = [...prevDataList.codesAndVehicleGroup];
      const originalOptionValue =
        creatorEditChangeTableData?.codesAndVehicleGroup?.[mainIndex]
          ?.vehicleCountryGroup?.[subIndex1]?.countryGroupList?.[subIndex2]
          ?.option;
      const newOptionValue = selectedOption ? selectedOption.value : "";

      updatedTableData[mainIndex].vehicleCountryGroup[
        subIndex1
      ].countryGroupList[subIndex2] = {
        ...updatedTableData[mainIndex].vehicleCountryGroup[subIndex1]
          .countryGroupList[subIndex2],
        option: newOptionValue,
        isUpdated: newOptionValue !== originalOptionValue,
      };

      return {
        ...prevDataList,
        codesAndVehicleGroup: updatedTableData,
      };
    });
  };

  const[remarksData, setRemarksData] = useState<any>([]);

  // Handle Select Change

  const handleSave = (text: string) => {
    const changeRequestData: any[] = [];

    // Collect the change request data
    dataList.codesAndVehicleGroup.forEach((data1: any) => {
      data1.vehicleCountryGroup.forEach((data2: any) => {
        data2.countryGroupList.forEach((data: any) => {
          if (data.option !== data.optionOriginalValue) {
            const remarksDataItem = remarksData.find(
              (item) => item.codeId === data1.codeId
            );            
            changeRequestData.push({
              id: data.changeRequestTransactionId,
              vehicleCountryGroupMappingId: data.vehicleCountryGroupMappingId,
              codeId: data1.codeId,
              optionId: dropdownOption.find(
                (option: any) => option.value === data.option
              )?.id,
              remarks: remarksDataItem && remarksDataItem.remarks ? remarksDataItem.remarks : "",
              cr: remarksDataItem && remarksDataItem.cr ? remarksDataItem.cr : "",
              coKem: remarksDataItem && remarksDataItem.cO_KEM ? remarksDataItem.cO_KEM : "",
            });
          }
        });
      });
    });

    console.log(changeRequestData, "changeRequestData");
    
    // Prepare the final payload with updated structure
    const payload = {
      button: text,
      requestId: dataList.changeRequestId,
      changeRequestTransactionInputModel: changeRequestData,
    };

    // Call the API
    if (text === "Save") {
      setSaveGotoCreator(true);
      props.getSaveEditCodeList(payload);
    } else if (text === "Submit") {
      setGoToCreator(true);
      props.getSubmitEditCodeList(payload);
    }
  };


  const [goToCreator, setGoToCreator] = useState(false);
  const[saveGotoCreator, setSaveGotoCreator] = useState(false);

  useEffect(() => {    
    if (goToCreator) {
      if (submitEditCodeList?.httpStatusMessage === "Success") {
        setGoToCreator(false);
        // history("/creator-request");
        history(-1);
      }
    }
  }, [submitEditCodeList]);

  useEffect(() => { 
    
    if (saveGotoCreator) {
      if (saveEditCodeList?.httpStatusMessage === "Success") {
        setSaveGotoCreator(false);
        history("/creator-request");
      }
      
    }
    
  }, [saveEditCodeList])
  

  const handleRemarksChange = ((value, index, type, codeid) => {
    const isExist = remarksData.some((item) => item.codeId === codeid);
    if (isExist) {
      setRemarksData((prevState) =>
        prevState.map((item) =>
          item.codeId === codeid ? { ...item, [type]: value } : item
        )
      );
    } else {
      setRemarksData((prevState) => [
        ...prevState,
        { codeId: codeid, [type]: value },
      ]);
    }

    // setDataList({
    //   ...dataList,
    //   codesAndVehicleGroup: dataList.codesAndVehicleGroup.map((item, i) => 
    //     i === index ? { ...item, [type]: value } : item
    //   )
    // });

  });

  useEffect(() => {
    console.log("remarksData", remarksData);
  }, [remarksData]);


  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Edit Code List" pageTitle="Code List" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex card-bg">
                  <h4 className="card-title mb-0 flex-grow-1">Code Lists</h4>
                  <div className="flex-shrink-0">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <div className="input-group gap-3 justify-content-md-end">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                          <Button
                            className="btn btn-primary btn-custom-blue"
                            type="button"
                            color="light"
                            onClick={() => handleSave("Save")}
                          >
                            Save
                          </Button>
                        </div>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                          <Button
                            className="btn btn-primary btn-custom-blue"
                            type="button"
                            color="light"
                            onClick={() => handleSave("Submit")}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="card-padding">
                  <div className="live-preview">
                    <div
                      className="table-responsive table-card"
                      style={{ maxHeight: "650px" }}
                    >
                      <table className="table align-middle table-bordered table-striped-columns table-nowrap table-striped mb-0">
                        <thead
                          className="table-light"
                          style={{
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                          }}
                        >
                          <tr>
                            {staticHeader.map((code, index) => (
                              <th
                                key={index}
                                scope="col"
                                rowSpan={2}
                                className="center"
                              >
                                {code}
                              </th>
                            ))}
                            {dataList?.codesAndVehicleGroup?.[0]?.vehicleCountryGroup?.map(
                              (data: any, index: any) => (
                                <th
                                  key={index}
                                  colSpan={data?.countryGroupList?.length}
                                  className="center"
                                >
                                  {data.vehicleModel}
                                </th>
                              )
                            )}
                          </tr>
                          <tr>
                            {countryGroupList.map((data: any) =>
                              data.map((data: any, index) => (
                                <td
                                  key={index}
                                  style={{ padding: "40px" }}
                                  className="center"
                                >
                                  {data.countryGroup}
                                </td>
                              ))
                            )}
                          </tr>
                        </thead>
                        {dataList?.codesAndVehicleGroup?.length > 0 ? (
                          <tbody>
                            {dataList?.codesAndVehicleGroup?.map(
                              (data, mainIndex) => {
                                const isEditable = data?.vehicleCountryGroup?.some(
                                  (item) =>
                                    item.countryGroupList.some(
                                      (subItem) =>
                                        subItem.option !== subItem.optionOriginalValue
                                    )
                                );
                                return (
                                  <tr key={mainIndex}>
                                    <td className="center">{data.codeGroup}</td>
                                    <td className="center">{data.code}</td>
                                    <td
                                      style={{ whiteSpace: "normal" }}
                                      className="center"
                                    >
                                      {data.codeDescription}
                                    </td>
                                    <td
                                      style={{ whiteSpace: "normal" }}
                                      className="center"
                                    >
                                      {data.notes}
                                    </td>
                                    <td
                                      style={{ whiteSpace: "normal" }}
                                      className="center"
                                    >
                                      <textarea
                                        className="form-control"
                                        rows={3}
                                        defaultValue={data.remarks}
                                        disabled={!isEditable}
                                        onChange={(e) =>
                                          handleRemarksChange(
                                            e.target.value,
                                            mainIndex,
                                            "remarks",
                                            data.codeId
                                          )
                                        }
                                      />
                                    </td>
                                    <td
                                      style={{ whiteSpace: "normal" }}
                                      className="center"
                                    >
                                      <textarea
                                        className="form-control"
                                        rows={3}
                                        defaultValue={data.cr}
                                        disabled={!isEditable}
                                        onChange={(e) =>
                                          handleRemarksChange(
                                            e.target.value,
                                            mainIndex,
                                            "cr",
                                            data.codeId
                                          )
                                        }
                                      />
                                    </td>
                                    <td
                                      style={{ whiteSpace: "normal" }}
                                      className="center"
                                    >
                                      <textarea
                                        className="form-control"
                                        rows={3}
                                        defaultValue={data.cO_KEM}
                                        disabled={!isEditable}
                                        onChange={(e) =>
                                          handleRemarksChange(
                                            e.target.value,
                                            mainIndex,
                                            "cO_KEM",
                                            data.codeId
                                          )
                                        }
                                      />
                                    </td>
                                    {data?.vehicleCountryGroup?.map(
                                      (option, subIndex1) =>
                                        option?.countryGroupList?.map(
                                          (output, subIndex2) => (
                                            <td
                                              key={subIndex2}
                                              className="center"
                                              style={{
                                                backgroundColor:
                                                  output.option !==
                                                  output.optionOriginalValue
                                                    ? "#fafa0070"
                                                    : "",
                                              }}
                                            >
                                              <Select
                                                value={dropdownOption.find(
                                                  (opt) =>
                                                    opt.value === output.option
                                                )}
                                                isMulti={false}
                                                options={dropdownOption}
                                                isDisabled={!output.isEditable}
                                                onChange={(selectedOption) =>
                                                  handleSelectChange(
                                                    selectedOption,
                                                    mainIndex,
                                                    subIndex1,
                                                    subIndex2
                                                  )
                                                }
                                              />
                                            </td>
                                          )
                                        )
                                    )}
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan={9} className="center">
                                No Data Found
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                  <div className="d-none code-view">
                    <pre
                      className="language-markup"
                      style={{ height: "275px" }}
                    >
                      <code>{/* <Striped /> */}</code>
                    </pre>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  creatorEditChangeTableData: state.CodeListReducer.creatorEditChangeTableData,
  loading: state.CodeListReducer.loading,
  submitEditCodeList: state.CodeListReducer.submitEditCodeList,
  saveEditCodeList: state.CodeListReducer.saveEditCodeList,
});

const mapDispatchToProps = (dispatch) => ({
  getSubmitEditCodeList: (data) => dispatch(getSubmitEditCodeList(data)),
  getSaveEditCodeList: (data) => dispatch(getSaveEditCodeList(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCodeList);
