import { takeEvery, call, put } from "redux-saga/effects";
import { baseAPI } from "../../helpers/api_helper.js"
import { toast } from 'react-toastify';
import { loginsSuccess, loginsFailure, refreshTokenSuccess, refreshTokenFailure } from "./action";
import { AuthenticationActionTypes } from "./actionType";
import { ConfigAPI } from "../../helpers/url_helper";

// Get All the budget by project id
function* workerGetLoginDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.LOGIN_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(loginsSuccess({
        payload: data,
        data: data.data,
      }))
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(loginsFailure(data))
    }
  } catch (error) {
    yield put(loginsFailure(error))
  }
}

function* workerRefreshTokenDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.REFRESH_TOKEN_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(refreshTokenSuccess({
        payload: data,
        data: data.data,
      }))
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(refreshTokenFailure(data))
    }
  } catch (error) {
    yield put(refreshTokenFailure(error))
  }
}

function* AuthenticationSaga() {
    yield takeEvery(AuthenticationActionTypes.LOGIN_REQUEST, workerGetLoginDetails);
    yield takeEvery(AuthenticationActionTypes.REFRESH_TOKEN_REQUEST, workerRefreshTokenDetails);
    
  }
  
  export default AuthenticationSaga;
