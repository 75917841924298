import { CodeBuilderActionTypes } from "./actionType";


const initialState = {
    isLoading: false,
    loading: false,
    items: [],
    errors: {},
    inputBooleanResponse: {},
    getModifidedCombination: {},
    getBauMasterData: {},
    getSancheckOldcodeData: {},
    getSancheckVarientData: {},
    sanCheckExcelExport: {},
    getCodesDropdownData: {},
    getSelectedCodeTruthTable: {},
    getBooleanlogicCode: {},
    getBooleanModifiedCombination: {},
    getCodeBuilderVehicleGroup: {},
}

const CodeBuilderReducer = (state = initialState, action) => {
    const response = action.data;
    switch (action.type) {
        case CodeBuilderActionTypes.INIT_GET_CODEBUILDER_DETAILS_MODULE:
            return {
                ...state, loading: false, isLoading: false, items: [], inputBooleanResponse: {}, getModifidedCombination: {}, getBauMasterData: {}, getSancheckOldcodeData: {}, getSancheckVarientData: {}, sanCheckExcelExport: {}, getCodesDropdownData: {}, getSelectedCodeTruthTable: {}, getBooleanlogicCode: {}, getBooleanModifiedCombination: {}, getCodeBuilderVehicleGroup: {}
            }
        case CodeBuilderActionTypes.UPDATE_INPUT_BOOLEAN_REQUEST:
            return { ...state, loading: true, inputBooleanResponse: {} }
        case CodeBuilderActionTypes.UPDATE_INPUT_BOOLEAN_SUCCESS:
            return { ...state, loading: false, inputBooleanResponse: response.data }
        case CodeBuilderActionTypes.UPDATE_INPUT_BOOLEAN_FAILED:
            return { ...state, loading: false, errors: action.error }

        case CodeBuilderActionTypes.GET_MODIFIDED_COMBINATION_REQUEST:
            return { ...state, loading: true, getModifidedCombination: {} }
        case CodeBuilderActionTypes.GET_MODIFIDED_COMBINATION_SUCCESS:
            return { ...state, loading: false, getModifidedCombination: response.data }
        case CodeBuilderActionTypes.GET_MODIFIDED_COMBINATION_FAILED:
            return { ...state, loading: false, errors: action.error }

        case CodeBuilderActionTypes.GET_BAU_MASTER_DATA_REQUEST:
            return { ...state, loading: true, getBauMasterData: {} }
        case CodeBuilderActionTypes.GET_BAU_MASTER_DATA_SUCCESS:
            return { ...state, loading: false, getBauMasterData: response.data }
        case CodeBuilderActionTypes.GET_BAU_MASTER_DATA_FAILED:
            return { ...state, loading: false, errors: action.error }

        case CodeBuilderActionTypes.GET_SANCHECK_OLDCODE_REQUEST:
            return { ...state, loading: true, getSancheckOldcodeData: {} }
        case CodeBuilderActionTypes.GET_SANCHECK_OLDCODE_SUCCESS:
            return { ...state, loading: false, getSancheckOldcodeData: response.data }
        case CodeBuilderActionTypes.GET_SANCHECK_OLDCODE_FAILED:
            return { ...state, loading: false, errors: action.error }

        case CodeBuilderActionTypes.GET_SANCHECK_GET_VARIENT_REQUEST:
            return { ...state, loading: true, getSancheckVarientData: {} }
        case CodeBuilderActionTypes.GET_SANCHECK_GET_VARIENT_SUCCESS:
            return { ...state, loading: false, getSancheckVarientData: response.payload.dataList }
        case CodeBuilderActionTypes.GET_SANCHECK_GET_VARIENT_FAILED:
            return { ...state, loading: false, errors: action.error }

        case CodeBuilderActionTypes.SANCHECK_EXCELEXPORT_REQUEST:
            return { ...state, loading: true, sanCheckExcelExport: {} }
        case CodeBuilderActionTypes.SANCHECK_EXCELEXPORT_SUCCESS:
            return { ...state, loading: false, sanCheckExcelExport: response.payload }
        case CodeBuilderActionTypes.SANCHECK_EXCELEXPORT_FAILED:
            return { ...state, loading: false, errors: action.error }

        case CodeBuilderActionTypes.GET_CODES_DROPDOWN_REQUEST:
            return { ...state, loading: false, getCodesDropdownData: {} }
        case CodeBuilderActionTypes.GET_CODES_DROPDOWN_SUCCESS:
            return { ...state, loading: false, getCodesDropdownData: response.payload }
        case CodeBuilderActionTypes.GET_CODES_DROPDOWN_FAILED:
            return { ...state, loading: false, errors: action.error }

        case CodeBuilderActionTypes.GET_SELECTED_CODE_TRUTHTABLE_REQUEST:
            return { ...state, loading: true, getSelectedCodeTruthTable: {} }
        case CodeBuilderActionTypes.GET_SELECTED_CODE_TRUTHTABLE_SUCCESS:
            return { ...state, loading: false, getSelectedCodeTruthTable: response.data }
        case CodeBuilderActionTypes.GET_SELECTED_CODE_TRUTHTABLE_FAILED:
            return { ...state, loading: false, errors: action.error }

        case CodeBuilderActionTypes.GET_BOOLEANLOGIC_LOGICCHECK_REQUEST:
            return { ...state, loading: true, getBooleanModifiedCombination: {} }
        case CodeBuilderActionTypes.GET_BOOLEANLOGIC_LOGICCHECK_SUCCESS:
            return { ...state, loading: false, getBooleanModifiedCombination: response.data }
        case CodeBuilderActionTypes.GET_BOOLEANLOGIC_LOGICCHECK_FAILED:
            return { ...state, loading: false, errors: action.error }

        case CodeBuilderActionTypes.GET_BOOLEANLOGIC_CODEGENERATION_REQUEST:
            return { ...state, loading: true, getBooleanlogicCode: {} }
        case CodeBuilderActionTypes.GET_BOOLEANLOGIC_CODEGENERATION_SUCCESS:
            return { ...state, loading: false, getBooleanlogicCode: response.data }
        case CodeBuilderActionTypes.GET_BOOLEANLOGIC_CODEGENERATION_FAILED:
            return { ...state, loading: false, errors: action.error }

        case CodeBuilderActionTypes.GET_CODEBUILDER_VEHICLE_GROUP_REQUEST:
            return { ...state, loading: true, getCodeBuilderVehicleGroup: {} }
        case CodeBuilderActionTypes.GET_CODEBUILDER_VEHICLE_GROUP_SUCCESS:
            return { ...state, loading: false, getCodeBuilderVehicleGroup: response?.payload}
        case CodeBuilderActionTypes.GET_CODEBUILDER_VEHICLE_GROUP_FAILED:
            return { ...state, loading: false, errors: action.error }

        default: {
            return state;
        }
    }
}

export default CodeBuilderReducer;
