// Import the action types
import { CodeBuilderActionTypes } from "./actionType";

// Define the action creators
export const initCodeBuilderModule = (data) => (
    {
    type: CodeBuilderActionTypes.INIT_GET_CODEBUILDER_DETAILS_MODULE,
});

export const searchBooleanLogicAction = (data) => (
    {
    type: CodeBuilderActionTypes.UPDATE_INPUT_BOOLEAN_REQUEST,
    data
});

export const searchBooleanLogicSuccess = (data) => {
    return {
        type: CodeBuilderActionTypes.UPDATE_INPUT_BOOLEAN_SUCCESS,
        data
    };
};

export const searchBooleanLogicFailure = (error) => ({
    type: CodeBuilderActionTypes.UPDATE_INPUT_BOOLEAN_FAILED,
    error
});

export const modifidedCombinationAction = (data) => (
    {
    type: CodeBuilderActionTypes.GET_MODIFIDED_COMBINATION_REQUEST,
    data
});

export const modifidedCombinationSuccess = (data) => {
    return {
        type: CodeBuilderActionTypes.GET_MODIFIDED_COMBINATION_SUCCESS,
        data
    };
};

export const modifidedCombinationFailure = (error) => ({
    type: CodeBuilderActionTypes.GET_MODIFIDED_COMBINATION_FAILED,
    error
});

export const getBauMasterDataAction = (data) => (
    {
    type: CodeBuilderActionTypes.GET_BAU_MASTER_DATA_REQUEST,
    data
});

export const getBauMasterDataSuccess = (data) => {
    return {
        type: CodeBuilderActionTypes.GET_BAU_MASTER_DATA_SUCCESS,
        data
    };
};

export const getBauMasterDataFailure = (error) => ({
    type: CodeBuilderActionTypes.GET_BAU_MASTER_DATA_FAILED,
    error
});

export const getSanCheckOldcodeAction = (data) => (
    {
    type: CodeBuilderActionTypes.GET_SANCHECK_OLDCODE_REQUEST,
    data
});

export const getSanCheckOldcodeSuccess = (data) => {
    return {
        type: CodeBuilderActionTypes.GET_SANCHECK_OLDCODE_SUCCESS,
        data
    };
};

export const getSanCheckOldcodeFailure = (error) => ({
    type: CodeBuilderActionTypes.GET_SANCHECK_OLDCODE_FAILED,
    error
});

export const getSanCheckVarientAction = (data) => (
    {
    type: CodeBuilderActionTypes.GET_SANCHECK_GET_VARIENT_REQUEST,
    data
});

export const getSanCheckVarientSuccess = (data) => {
    return {
        type: CodeBuilderActionTypes.GET_SANCHECK_GET_VARIENT_SUCCESS,
        data
    };
};

export const getSanCheckVarientFailure = (error) => ({
    type: CodeBuilderActionTypes.GET_SANCHECK_GET_VARIENT_FAILED,
    error
});

export const sanCheckExcelExportAction = (data) => (
    {
    type: CodeBuilderActionTypes.SANCHECK_EXCELEXPORT_REQUEST,
    data
});

export const sanCheckExcelExportSuccess = (data) => {
    return {
        type: CodeBuilderActionTypes.SANCHECK_EXCELEXPORT_SUCCESS,
        data
    };
};

export const sanCheckExcelExportFailure = (error) => ({
    type: CodeBuilderActionTypes.SANCHECK_EXCELEXPORT_FAILED,
    error
});

export const getCodesDropDownAction = (data) => (
    {
    type: CodeBuilderActionTypes.GET_CODES_DROPDOWN_REQUEST,
    data
});

export const getCodesDropDownSuccess = (data) => {
    return {
        type: CodeBuilderActionTypes.GET_CODES_DROPDOWN_SUCCESS,
        data
    };
};

export const getCodesDropDownFailure = (error) => ({
    type: CodeBuilderActionTypes.GET_CODES_DROPDOWN_FAILED,
    error
});

export const getSelectedCodeTruthTableAction = (data) => (
    {
    type: CodeBuilderActionTypes.GET_SELECTED_CODE_TRUTHTABLE_REQUEST,
    data
});

export const getSelectedCodeTruthTableSuccess = (data) => {
    return {
        type: CodeBuilderActionTypes.GET_SELECTED_CODE_TRUTHTABLE_SUCCESS,
        data
    };
};

export const getSelectedCodeTruthTableFailure = (error) => ({
    type: CodeBuilderActionTypes.GET_SELECTED_CODE_TRUTHTABLE_FAILED,
    error
});

export const getBooleanLogicCheckAction = (data) => (
    {
        type: CodeBuilderActionTypes.GET_BOOLEANLOGIC_LOGICCHECK_REQUEST,
        data
    }
)

export const getBooleanLogicCheckSuccess = (data) => {
    return {
        type: CodeBuilderActionTypes.GET_BOOLEANLOGIC_LOGICCHECK_SUCCESS,
        data
    };
};

export const getBooleanLogicCheckFailure = (error) => ({
    type: CodeBuilderActionTypes.GET_BOOLEANLOGIC_LOGICCHECK_FAILED,
    error
});

export const getBooleanLogicCodeGenerationAction = (data) => (
    {
        type: CodeBuilderActionTypes.GET_BOOLEANLOGIC_CODEGENERATION_REQUEST,
        data
    }
)

export const getBooleanLogicCodeGenerationSuccess = (data) => {
    return {
        type: CodeBuilderActionTypes.GET_BOOLEANLOGIC_CODEGENERATION_SUCCESS,
        data
    };
};

export const getBooleanLogicCodeGenerationFailure = (error) => ({
    type: CodeBuilderActionTypes.GET_BOOLEANLOGIC_CODEGENERATION_FAILED,
    error
});

export const getCodeBuilderVehicleGroupAction = (data) => (
    {
    type: CodeBuilderActionTypes.GET_CODEBUILDER_VEHICLE_GROUP_REQUEST,
    data
});

export const getCodeBuilderVehicleGroupSuccess = (data) => {
    return {
        type: CodeBuilderActionTypes.GET_CODEBUILDER_VEHICLE_GROUP_SUCCESS,
        data
    };
};

export const getCodeBuilderVehicleGroupFailure = (error) => ({
    type: CodeBuilderActionTypes.GET_CODEBUILDER_VEHICLE_GROUP_FAILED,
    error
});
