import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import Select from "react-select";
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from 'reactstrap';
import { Pagination } from '@mui/material';
import DeleteModal from '../DeleteModel/DeleteModel';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Loader from '../Loader/Loader';
import { getAllCodeAction, getAllCodeGroupDropdownAction, insertOrUpdateCodeAction, deleteCodeAction } from '../../store/actions';

const Code = (props: any) => {
    document.title = "Code";
    const { geyAllCode, getAllCodeGroupDropdown, insertOrUpdateCode, deleteCode, getLoginData, loading } = props;
    const location = useLocation();
    const { data } = location.state || { data: [] };
    const [locationData, setLocationData] = useState(data);
    const [selectedGroup2, setSelectedGroup2] = useState(null);
    const [openModel, setOpenModel] = useState(locationData?.id ? true : false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [todo, setTodo] = useState(null);
    const [totalCount, setTotalCount] = useState<any>("");
    const [getAllCodeLists, setGetAllCodeLists] = useState<any>(null);
    const [sort, setSort] = useState("");
    const recordsPerPage = 10;
    const totalPages = Math.ceil(Number(totalCount) / recordsPerPage);
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [allCodeGroupDropdownDatas, setAllCodeGroupDropdownDatas] = useState<any>(null);
    const [editId, seteditId] = useState<any>("");
    const [editData, setEditData] = useState<any>({ description: locationData?.description, name: locationData?.code });
    const errors: any = {}
    const [formValidation, setFormValiodation] = useState<any>({});
    const [groupId, setgroupId] = useState<any>("");
    const LoginRoleData = sessionStorage.getItem("loginRoleAccess")
    const RoleResponse = JSON.parse(LoginRoleData);
    const Role = RoleResponse?.find(data => data?.name === "Master")?.subModules?.find(val => val?.submenuName === "Code")?.access

    const Group = allCodeGroupDropdownDatas?.map((item: any) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const handleEdit = (code: any) => {
        setOpenModel(true);
        setEditData(code);
        seteditId(code?.id);
        setgroupId(code?.codeGroupId);
        setSelectedGroup2({
            id: code?.codeGroupId,
            label: code?.codeGroupValue,
            value: code?.codeGroupValue,
            isSelected: code?.isActive,
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleAdd = () => {
        setOpenModel(true);
    }

    const handleSave = () => {
        if (!editData?.name) {
            errors.name = 'Please fill the field'
        }
        if (!editData?.description) {
            errors.description = 'Please fill the field'
        }
        if (!groupId) {
            errors.groupId = 'Please fill the field'
        }
        setFormValiodation(errors);
        const Data = {
            id: editId ? editId : 0,
            name: editData?.name,
            description: editData?.description,
            codeGroupId: groupId,
            remarks: editData?.remarks,
            codeRequestId: locationData?.id
        };
        setCurrentPage(editId ? currentPage : 1)
        if (editData?.name && editData?.description && groupId) {
            props.insertOrUpdateCodeAction(Data)
        }
    }

    useEffect(() => {
        if (insertOrUpdateCode?.httpStatusCode === 200) {
            setOpenModel(false);
            handleClear();
            setLocationData([]);
            props.getAllCodeAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" })
        }
    }, [insertOrUpdateCode]);

    useEffect(() => {
        setLocationData(data);
        return () => {
            setLocationData([]);
        };
    }, [geyAllCode]);

    const handleClear = () => {
        setEditData({});
        setSelectedGroup2(null);
        setFormValiodation({});
        seteditId("");
        setgroupId("");
    }

    function handleSelectGroups2(selectedGroup2) {
        setSelectedGroup2(selectedGroup2);
        setgroupId(selectedGroup2?.id);
    }

    const handleDeleteTodo = () => {
        setDeleteModal(false);
        props.deleteCodeAction(todo);
        setCurrentPage(1)
    };

    const onClickTodoDelete = (todo: any) => {
        setTodo(todo);
        setDeleteModal(true);
    };

    const onClickPagination = (event, value) => {
        setCurrentPage(value);
    };

    const onSearchChange = (event: any) => {
        setSearchKeyword(event.target.value);
        props.getAllCodeAction({ page: "1", search: event.target.value, sort: sort, OrderByColumnName: "" })
        setCurrentPage(1);
    };

    const onSortChange = (keys: any) => {
        const newSortOrder = sort === 'asc' ? 'desc' : 'asc';
        setSort(newSortOrder);
        props.getAllCodeAction({ page: currentPage, search: searchKeyword, sort: newSortOrder, OrderByColumnName: keys, SortDirection: newSortOrder });
    };

    useEffect(() => {
        props.getAllCodeAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" });
        setTotalCount(geyAllCode?.totalRowsCount);
    }, [currentPage, deleteCode])

    useEffect(() => {
        setTotalCount(geyAllCode?.totalRowsCount);
    }, [geyAllCode])

    useEffect(() => {
        setGetAllCodeLists(geyAllCode)
    }, [geyAllCode]);

    useEffect(() => {
        props.getAllCodeGroupDropdownAction();
    }, [])

    useEffect(() => {
        setAllCodeGroupDropdownDatas(getAllCodeGroupDropdown?.data)
    }, [getAllCodeGroupDropdown])

    return (
        <React.Fragment>
            {loading && <Loader />}
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteTodo()}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Code" pageTitle="Masters" />
                    {openModel &&
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader className='card-bg'><h4 className="card-title mb-0 ">Add Code</h4> </CardHeader>
                                    <CardBody>
                                        <div>
                                            <div>
                                                <Row>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Code <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter Code" value={editData.name ? editData.name : ""} onChange={(e) => setEditData({ ...editData, name: e.target.value })} />
                                                            <span className='text-validation'>{formValidation.name}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Code Description <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter Code description" value={editData.description ? editData.description : ""} onChange={(e) => setEditData({ ...editData, description: e.target.value })} />
                                                            <span className='text-validation'>{formValidation.description}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Code Group <span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                isClearable={true}
                                                                value={selectedGroup2}
                                                                onChange={(e) => {
                                                                    handleSelectGroups2(e);
                                                                }}
                                                                options={Group}
                                                            />
                                                            <span className='text-validation'>{formValidation.groupId}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={8} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Remarks</Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter remarks" value={editData.remarks ? editData.remarks : ""} onChange={(e) => setEditData({ ...editData, remarks: e.target.value })} />
                                                            <span className='text-validation'>{formValidation.remarks}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3 pt-4">
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                                <Button className="btn btn-primary mb-2 btn-custom-blue" type="button" color='light' onClick={() => handleSave()}>Save</Button>
                                                                <Button className="btn btn-primary me-3 mb-2 btn-custom-blue" type="button" color='light' onClick={handleClear}>Clear</Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex card-bg">
                                    <h4 className="card-title mb-0 flex-grow-1">Code List</h4>
                                    <div className="flex-shrink-0">
                                        <div className="form-check form-switch form-switch-right form-switch-md">
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                {Role && Role?.createAccess && <Button className="btn btn-primary btn-custom-blue" type="button" color='light' onClick={() => handleAdd()}>+ Add</Button>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <Col className="col-sm"> */}
                                    <div className="d-flex justify-content-sm-end">
                                        <div className="search-box ms-2">
                                            <input type="text" className="form-control search" placeholder="Search..." onChange={onSearchChange} />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                    {/* </Col> */}
                                </CardHeader>
                                <CardBody className='card-padding'>
                                    <div className="live-preview">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap table-striped mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No </th>
                                                        <th scope="col">Codes <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('name')}></i></th>
                                                        <th scope="col">Code Description <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('description')}></i></th>
                                                        <th scope="col">Code Group <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('aggregateGroup')}></i></th>
                                                        <th scope="col">Remarks <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('remarks')}></i></th>
                                                        {(Role && Role?.editAccess || Role && Role?.editAccess) && <th scope="col" style={{ width: 50 }}>Action</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getAllCodeLists?.dataRows && getAllCodeLists?.dataRows?.length > 0 ? (
                                                        getAllCodeLists?.dataRows?.map((code: any, index: number) => (
                                                            <tr key={code?.id}>
                                                                <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>
                                                                <td>{code?.name}</td>
                                                                <td>{code?.description}</td>
                                                                <td>{code?.codeGroupValue}</td>
                                                                <td>{code?.remarks ? code?.remarks : "-"}</td>
                                                                {(Role && Role?.editAccess || Role && Role?.editAccess) &&
                                                                    <td>
                                                                        <div className="hstack gap-2">
                                                                            {Role && Role?.editAccess && <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleEdit(code)}>
                                                                                <i className="ri-pencil-fill align-bottom" />
                                                                            </button>
                                                                            }
                                                                            {Role && Role?.deleteAccess && <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete(code?.id)}>
                                                                                <i className="ri-delete-bin-5-fill align-bottom" />
                                                                            </button>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td className='no-data-foun' colSpan={10} >No data found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-none code-view">
                                        <pre className="language-markup" style={{ "height": "275px" }}>
                                            <code>
                                            </code>
                                        </pre>
                                    </div>
                                    {totalCount > 10 &&
                                        <Row className="g-4 mt-3">
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Pagination count={totalPages} page={currentPage} onChange={onClickPagination} shape="rounded" />
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    geyAllCode: state.MasterReducer.geyAllCode,
    getAllCodeGroupDropdown: state.MasterReducer.getAllCodeGroupDropdown,
    insertOrUpdateCode: state.MasterReducer.insertOrUpdateCode,
    deleteCode: state.MasterReducer.deleteCode,
    getLoginData: state.AuthenticationReducer?.getLoginData?.data?.userRoleAccessList,
    loading: state.MasterReducer.loading
})

const mapDispatchToProps = (dispatch: any) => ({
    getAllCodeAction: (data: any) => {
        dispatch(getAllCodeAction(data))
    },
    getAllCodeGroupDropdownAction: (data: any) => {
        dispatch(getAllCodeGroupDropdownAction(data))
    },
    insertOrUpdateCodeAction: (data: any) => {
        dispatch(insertOrUpdateCodeAction(data))
    },
    deleteCodeAction: (data: any) => {
        dispatch(deleteCodeAction(data))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Code);
