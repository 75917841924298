import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { Action } from 'redux';

// Login Redux States
import { FORGET_PASSWORD } from './actionTypes';
import { userForgetPasswordSuccess, userForgetPasswordError } from './actions';

// Include Both Helper Files with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import {
  postFakeForgetPwd,
  postJwtForgetPwd,
} from '../../../helpers/fakebackend_helper';

// Define the types for the action payloads
interface ForgetPasswordAction extends Action<typeof FORGET_PASSWORD> {
  payload: {
    user: {
      email: string;
    };
    
    // history?: any; // Adjust the type of `history` if needed
  };
}

// Define the types for the API responses
type ApiResponse = any; // Adjust this type based on the actual response shape

const fireBaseBackend = getFirebaseBackend();

// If user is successfully sent mail link then dispatch redux actions directly from here.
function* forgetUser(action: ForgetPasswordAction): Generator<any> {
  try {
    const { user } = action.payload;

    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response: ApiResponse = yield call(fireBaseBackend.forgetPassword, user.email);
      if (response) {
        yield put(userForgetPasswordSuccess('Reset link is sent to your mailbox, check there first'));
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      // @ts-ignore
      const response: ApiResponse = yield call(postJwtForgetPwd, '/jwt-forget-pwd', {
        email: user.email,
      });
      if (response) {
        yield put(userForgetPasswordSuccess('Reset link is sent to your mailbox, check there first'));
      }
    } else if (process.env.REACT_APP_API_URL) {
      const response: ApiResponse = yield call(postFakeForgetPwd, user);
      if (response) {
        yield put(userForgetPasswordSuccess('Reset link is sent to your mailbox, check there first'));
      }
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
