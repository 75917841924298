// Import the action types
import { CodeConfigurationActionTypes } from "./actionType";

// Define the action creators
export const initCodeConfigurationModule = (data) => (
    {
    type: CodeConfigurationActionTypes.INIT_GET_CODECONFIGURATION_DETAILS_MODULE,
});

export const getModelAction = (data) => (
    {
    type: CodeConfigurationActionTypes.GET_INPUT_MODEL_REQUEST,
    data
});

export const getModelSuccess = (data) => {
    return {
        type: CodeConfigurationActionTypes.GET_INPUT_MODEL_SUCCESS,
        data
    };
};

export const getModelFailure = (error) => ({
    type: CodeConfigurationActionTypes.GET_INPUT_MODEL_FAILED,
    error
});

export const getCountryAction = (data) => (
    {
    type: CodeConfigurationActionTypes.GET_INPUT_COUNTRY_REQUEST,
    data
});

export const getCountrySuccess = (data) => {
    return {
        type: CodeConfigurationActionTypes.GET_INPUT_COUNTRY_SUCCESS,
        data
    };
};

export const getCountryFailure = (error) => ({
    type: CodeConfigurationActionTypes.GET_INPUT_COUNTRY_FAILED,
    error
});

export const bindCodeBOMAction = (data) => (
    {
    type: CodeConfigurationActionTypes.BING_CODE_BOM_REQUEST,
    data
});

export const bindCodeBOMSuccess = (data) => {
    return {
        type: CodeConfigurationActionTypes.BING_CODE_BOM_SUCCESS,
        data
    };
};

export const bindCodeBOMFailure = (error) => ({
    type: CodeConfigurationActionTypes.BING_CODE_BOM_FAILED,
    error
});

export const updateCodelistCodeBOMAction = (data) => (
    {
    type: CodeConfigurationActionTypes.UPDATE_CODE_LIST_CODEBOM_REQUEST,
    data
});

export const updateCodelistCodeBOMSuccess = (data) => {
    return {
        type: CodeConfigurationActionTypes.UPDATE_CODE_LIST_CODEBOM_SUCCESS,
        data
    };
};

export const updateCodelistCodeBOMFailure = (error) => ({
    type: CodeConfigurationActionTypes.UPDATE_CODE_LIST_CODEBOM_FAILED,
    error
});

export const codeConfiguratiorExcelExportAction = (data) => (
    {
    type: CodeConfigurationActionTypes.CODE_CONFIGURATIOR_EXCELEXPORT_REQUEST,
    data
});

export const codeConfiguratiorExcelExportSuccess = (data) => {
    return {
        type: CodeConfigurationActionTypes.CODE_CONFIGURATIOR_EXCELEXPORT_SUCCESS,
        data
    };
};

export const codeConfiguratiorExcelExportFailure = (error) => ({
    type: CodeConfigurationActionTypes.CODE_CONFIGURATIOR_EXCELEXPORT_FAILED,
    error
});

export const getConfiguratorVehicleGroupAction = (data) => (
    {
    type: CodeConfigurationActionTypes.GET_CONFIGURATOR_VEHICLE_GROUP_REQUEST,
    data
});

export const getConfiguratorVehicleGroupSuccess = (data) => {
    return {
        type: CodeConfigurationActionTypes.GET_CONFIGURATOR_VEHICLE_GROUP_SUCCESS,
        data
    };
};

export const getConfiguratorVehicleGroupFailure = (error) => ({
    type: CodeConfigurationActionTypes.GET_CONFIGURATOR_VEHICLE_GROUP_FAILED,
    error
});
