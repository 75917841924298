import { AuthenticationActionTypes } from "./actionType";

export const loginsAction = (data) => (
    {
        type: AuthenticationActionTypes.LOGIN_REQUEST,
        data
    });

export const loginsSuccess = (data) => {
    return {
        type: AuthenticationActionTypes.LOGIN_SUCCESS,
        data
    };
};

export const loginsFailure = (error) => ({
    type: AuthenticationActionTypes.LOGIN_FAILED,
    error
});

export const refreshTokenAction = (data) => (
    {
        type: AuthenticationActionTypes.REFRESH_TOKEN_REQUEST,
        data
    });

export const refreshTokenSuccess = (data) => {
    return {
        type: AuthenticationActionTypes.REFRESH_TOKEN_SUCCESS,
        data
    };
};

export const refreshTokenFailure = (error) => ({
    type: AuthenticationActionTypes.REFRESH_TOKEN_FAILED,
    error
});
