export const AuthenticationActionTypes = {
    INIT_GET_AUTHENDICATION_MODULE: "INIT_GET_AUTHENDICATION_MODULE",

    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILED: "LOGIN_FAILED",

    REFRESH_TOKEN_REQUEST: "REFRESH_TOKEN_REQUEST",
    REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
    REFRESH_TOKEN_FAILED: "REFRESH_TOKEN_FAILED",

}