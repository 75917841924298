export const CodeConfigurationActionTypes = {
    INIT_GET_CODECONFIGURATION_DETAILS_MODULE: "INIT_GET_CODECONFIGURATION_DETAILS_MODULE",
    
    GET_INPUT_MODEL_REQUEST: "GET_INPUT_MODEL_REQUEST",
    GET_INPUT_MODEL_SUCCESS: "GET_INPUT_MODEL_SUCCESS",
    GET_INPUT_MODEL_FAILED: "GET_INPUT_MODEL_FAILED",

    GET_INPUT_COUNTRY_REQUEST: "GET_INPUT_COUNTRY_REQUEST",
    GET_INPUT_COUNTRY_SUCCESS: "GET_INPUT_COUNTRY_SUCCESS",
    GET_INPUT_COUNTRY_FAILED: "GET_INPUT_COUNTRY_FAILED",

    BING_CODE_BOM_REQUEST: "BING_CODE_BOM_REQUEST",
    BING_CODE_BOM_SUCCESS: "BING_CODE_BOM_SUCCESS",
    BING_CODE_BOM_FAILED: "BING_CODE_BOM_FAILED",

    UPDATE_CODE_LIST_CODEBOM_REQUEST: "UPDATE_CODE_LIST_CODEBOM_REQUEST",
    UPDATE_CODE_LIST_CODEBOM_SUCCESS: "UPDATE_CODE_LIST_CODEBOM_SUCCESS",
    UPDATE_CODE_LIST_CODEBOM_FAILED: "UPDATE_CODE_LIST_CODEBOM_FAILED",

    CODE_CONFIGURATIOR_EXCELEXPORT_REQUEST: "CODE_CONFIGURATIOR_EXCELEXPORT_REQUEST",
    CODE_CONFIGURATIOR_EXCELEXPORT_SUCCESS: "CODE_CONFIGURATIOR_EXCELEXPORT_SUCCESS",
    CODE_CONFIGURATIOR_EXCELEXPORT_FAILED: "CODE_CONFIGURATIOR_EXCELEXPORT_FAILED",

    GET_CONFIGURATOR_VEHICLE_GROUP_REQUEST: "GET_CONFIGURATOR_VEHICLE_GROUP_REQUEST",
    GET_CONFIGURATOR_VEHICLE_GROUP_SUCCESS: "GET_CONFIGURATOR_VEHICLE_GROUP_SUCCESS",
    GET_CONFIGURATOR_VEHICLE_GROUP_FAILED: "GET_CONFIGURATOR_VEHICLE_GROUP_FAILED",
  };
