export const CodeListActionTypes = {
    INIT_GET_CODE_LIST_MODULE: "INIT_GET_CODE_LIST_MODULE",

    GET_DROPDOWN_CODE_LIST_REQUEST: "GET_CODE_LIST_REQUEST",
    GET_DROPDOWN_CODE_LIST_SUCCESS: "GET_CODE_LIST_SUCCESS",
    GET_DROPDOWN_CODE_LIST_FAILED: "GET_CODE_LIST_FAILED",

    GET_VIEW_CODE_LIST_TABLE_REQUEST: "GET_VIEW_CODE_LIST_TABLE_REQUEST",
    GET_VIEW_CODE_LIST_TABLE_SUCCESS: "GET_VIEW_CODE_LIST_TABLE_SUCCESS",
    GET_VIEW_CODE_LIST_TABLE_FAILED: "GET_VIEW_CODE_LIST_TABLE_FAILED",

    SUBMIT_CODE_LIST_REQUEST: "SUBMIT_CODE_LIST_REQUEST",
    SUBMIT_CODE_LIST_SUCCESS: "SUBMIT_CODE_LIST_SUCCESS",
    SUBMIT_CODE_LIST_FAILED: "SUBMIT_CODE_LIST_FAILED",

    
    GET_DROPDOWN_VERSION_COMPARISON_REQUEST: "GET_DROPDOWN_VERSION_COMPARISON_REQUEST",
    GET_DROPDOWN_VERSION_COMPARISON_SUCCESS: "GET_DROPDOWN_VERSION_COMPARISON_SUCCESS",
    GET_DROPDOWN_VERSION_COMPARISON_FAILED: "GET_DROPDOWN_VERSION_COMPARISON_FAILED",

    GET_VERSION_COMPARISON_TABLE_REQUEST: "GET_VERSION_COMPARISON_TABLE_REQUEST",
    GET_VERSION_COMPARISON_TABLE_SUCCESS: "GET_VERSION_COMPARISON_TABLE_SUCCESS",
    GET_VERSION_COMPARISON_TABLE_FAILED: "GET_VERSION_COMPARISON_TABLE_FAILED",

    GET_CREATOR_TABLE_REQUEST: "GET_CREATOR_TABLE_REQUEST",
    GET_CREATOR_TABLE_SUCCESS: "GET_CREATOR_TABLE_SUCCESS",
    GET_CREATOR_TABLE_FAILED: "GET_CREATOR_TABLE_FAILED",

    GET_CREATOR_VIEW_TABLE_REQUEST: "GET_CREATOR_VIEW_TABLE_REQUEST",
    GET_CREATOR_VIEW_TABLE_SUCCESS: "GET_CREATOR_VIEW_TABLE_SUCCESS",
    GET_CREATOR_VIEW_TABLE_FAILED: "GET_CREATOR_VIEW_TABLE_FAILED",

    GET_CREATOR_EDIT_TABLE_REQUEST: "GET_CREATOR_EDIT_TABLE_REQUEST",
    GET_CREATOR_EDIT_TABLE_SUCCESS: "GET_CREATOR_EDIT_TABLE_SUCCESS",
    GET_CREATOR_EDIT_TABLE_FAILED: "GET_CREATOR_EDIT_TABLE_FAILED",

    SUBMIT_EDIT_CODE_LIST_REQUEST: "SUBMIT_EDIT_CODE_LIST_REQUEST",
    SUBMIT_EDIT_CODE_LIST_SUCCESS: "SUBMIT_EDIT_CODE_LIST_SUCCESS",
    SUBMIT_EDIT_CODE_LIST_FAILED: "SUBMIT_EDIT_CODE_LIST_FAILED",

    SAVE_EDIT_CODE_LIST_REQUEST: "SAVE_EDIT_CODE_LIST_REQUEST",
    SAVE_EDIT_CODE_LIST_SUCCESS: "SAVE_EDIT_CODE_LIST_SUCCESS",
    SAVE_EDIT_CODE_LIST_FAILED: "SAVE_EDIT_CODE_LIST_FAILED",

    GET_WORKFLOW_TABLE_REQUEST: "GET_WORKFLOW_TABLE_REQUEST",
    GET_WORKFLOW_TABLE_SUCCESS: "GET_WORKFLOW_TABLE_SUCCESS",
    GET_WORKFLOW_TABLE_FAILED: "GET_WORKFLOW_TABLE_FAILED",

    GET_WORKFLOW_COMBINE_REQUEST: "GET_WORKFLOW_COMBINE_REQUEST",
    GET_WORKFLOW_COMBINE_SUCCESS: "GET_WORKFLOW_COMBINE_SUCCESS",
    GET_WORKFLOW_COMBINE_FAILED: "GET_WORKFLOW_COMBINE_FAILED",

    GET_COUNTRY_COMPARISON_DROPDOWN_REQUEST: "GET_COUNTRY_COMPARISON_DROPDOWN_REQUEST",
    GET_COUNTRY_COMPARISON_DROPDOWN_SUCCESS: "GET_COUNTRY_COMPARISON_DROPDOWN_SUCCESS",
    GET_COUNTRY_COMPARISON_DROPDOWN_FAILED: "GET_COUNTRY_COMPARISON_DROPDOWN_FAILED",

    GET_COUNTRY_COMPARISON_TABLE_REQUEST: "GET_COUNTRY_COMPARISON_TABLE_REQUEST",
    GET_COUNTRY_COMPARISON_TABLE_SUCCESS: "GET_COUNTRY_COMPARISON_TABLE_SUCCESS",
    GET_COUNTRY_COMPARISON_TABLE_FAILED: "GET_COUNTRY_COMPARISON_TABLE_FAILED",

    GET_VIEW_CHANGES_DECISION_REQUEST: "GET_VIEW_CHANGES_DECISION_REQUEST",
    GET_VIEW_CHANGES_DECISION_SUCCESS: "GET_VIEW_CHANGES_DECISION_SUCCESS",
    GET_VIEW_CHANGES_DECISION_FAILED: "GET_VIEW_CHANGES_DECISION_FAILED",
}