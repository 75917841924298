import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Label,
  Card,
  CardFooter,
} from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import "../CodeConfiguration/style.css";
// import {
//   getDropdownVersionComparisonAction,
//   getVersionComparisonTableAction,
// } from "../../store/actions";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getViewChangesDecisionAction } from "../../store/actions";
// import tableData from "./delete.json";

const ViewChanges = (props: any) => {
  document.title = "Version Comparisons";

  const { creatorViewChangeTableData, loading, viewChangesDecisionData } =
    props;
  const [tableData, setTableData] = useState<any>([]);
  const [displayChangeStatus, setDisplayChangeStatus] = useState<any>(false);
  const [requestid, setRequestid] = useState<any>(0);
  const [remarksdata, setRemarksdata] = useState<any>("");
  const [isEditable, setIsEditable] = useState<any>(false);

  // const[changeDropdown,setChangeDropdown]=useState<any>([]);
  const navigate = useNavigate();
  const changeDropdown =
    creatorViewChangeTableData?.options?.map((option) => ({
      value: option.value,
      label: option.value,
      id: option.id,
    })) || [];
  //   const [tableData, setTableData] = useState<any>([]);

  //   useEffect(() => {
  //     props.getDropdownVersionComparisonAction(
  //       selectedVechicle ? selectedVechicle?.id : 0
  //     );
  //   }, []);

  //   useEffect(() => {
  //     setOptions(dropdownVersionComparison);
  //   }, [dropdownVersionComparison]);

  // Map vehicle groups

  //   useEffect(() => {
  //     if (tableData && Object.keys(tableData).length > 0) {
  //       setShowTableList(true);
  //     }
  //   }, [tableData]);

  useEffect(() => {
    if (Object.keys(creatorViewChangeTableData).length !== 0) {
      setTableData(creatorViewChangeTableData.codeVehicleRemarks);
      setDisplayChangeStatus(creatorViewChangeTableData.isWorkflowRequest);
      setRequestid(creatorViewChangeTableData.requestId);
      setIsEditable(creatorViewChangeTableData.isEditable);
      // setChangeDropdown(creatorViewChangeTableData.options);
    }
  }, [creatorViewChangeTableData]);

  const staticHeader = [
    "Code Group",
    "Code",
    "Code Description",
    "Notes",
    ...(tableData[0] && tableData[0].remarks
      ? tableData[0].remarks.map((r) => r.header)
      : []),
    ...(tableData[0] && tableData[0].cr
      ? tableData[0].cr.map((r) => r.header)
      : []),
    ...(tableData[0] && tableData[0].cO_KEM
      ? tableData[0].cO_KEM.map((r) => r.header)
      : []),
  ];

  const vehicleHeaders =
    tableData && tableData.length > 0
      ? tableData[0].vehicleCountryGroup.map((vehicle) => vehicle.vehicleModel)
      : [];

  const countryGroup =
    tableData?.length > 0
      ? tableData[0]?.vehicleCountryGroup.map((head) =>
          head.countryGroupList.map((head) => head.countryGroup)
        )
      : [];

  const version =
    tableData?.length > 0
      ? tableData[0]?.vehicleCountryGroup[0]?.countryGroupList[0]?.versionOption.map(
          (head) => head.version
        )
      : [];

  const handleBack = () => {
    navigate(-1);
  };

  const [gotoWorkflow, setGotoWorkflow] = useState<any>(false);
  const handleChangeStatus = (status: string) => {
    props.getViewChangesDecisionAction({
      workFlowStatus: status,
      requestId: requestid,
      comments: remarksdata,
    });
    setGotoWorkflow(true);
  };

  useEffect(() => {
    if (
      viewChangesDecisionData?.payload?.httpStatusCode === 200 &&
      gotoWorkflow
    ) {
      setGotoWorkflow(false);
      navigate(-1);
    }
  }, [viewChangesDecisionData]);

  const [changedData, setChangedData] = useState<any>([]);
  const handleChangesChange = (value, id, location) => {
    const isExist = changedData.some((item) => item.id === id);
    if (isExist) {
      setChangedData((prevState) =>
        prevState.map((item) =>
          item.id === id ? { ...item, [location]: value } : item
        )
      );
    } else {
      setChangedData((prevState) => [
        ...prevState,
        { id: id, [location]: value },
      ]);
    }
  };

  const handleSelectChange = (
    selectedOption,
    mainIndex,
    subIndex1,
    subIndex2,
    subIndex3
  ) => {
    setTableData((prevState) =>
      prevState.map((item, index) => {
        if (index === mainIndex) {
          return {
            ...item,
            vehicleCountryGroup: item.vehicleCountryGroup.map((vehicle, vIdx) => {
              if (vIdx === subIndex1) {
                return {
                  ...vehicle,
                  countryGroupList: vehicle.countryGroupList.map(
                    (country, cIdx) => {
                      if (cIdx === subIndex2) {
                        return {
                          ...country,
                          versionOption: country.versionOption.map(
                            (version, vOptIdx) => {
                              if (vOptIdx === subIndex3) {
                                return { ...version, option: selectedOption.value };
                              }
                              return version;
                            }
                          ),
                        };
                      }
                      return country;
                    }
                  ),
                };
              }
              return vehicle;
            }),
          };
        }
        return item;
      })
    );
  };
  

  useEffect(() => {
    console.log(changedData, "changedData");
  }, [changedData]);

  return (
    <React.Fragment>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="View Changes" pageTitle="Code List" />

          <Row>
            <Col lg={12}>
              {displayChangeStatus &&
              tableData !== null &&
              typeof tableData === "object" &&
              tableData?.length > 0 ? (
                <Card>
                  <CardHeader className="card-bg">
                    <h4 className="card-title mb-0 ">Change Status</h4>{" "}
                  </CardHeader>

                  <CardBody>
                    <div>
                      <Row>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-default"
                              className="form-label text-muted"
                            >
                              Remarks
                            </Label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea5"
                              rows={3}
                              onChange={(e) => setRemarksdata(e.target.value)}
                            ></textarea>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <div className="d-flex gap-3 justify-content-md-end ">
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          className="btn btn-primary btn-custom-blue"
                          type="button"
                          color="light"
                          onClick={() => handleChangeStatus("Approved")}
                        >
                          Approve
                        </Button>
                      </div>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          className="btn btn-primary btn-custom-blue"
                          type="button"
                          color="light"
                          onClick={() => handleChangeStatus("Rejected")}
                        >
                          Reject
                        </Button>
                      </div>
                    </div>
                  </CardFooter>
                </Card>
              ) : null}
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex card-bg">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Comparison Table
                  </h4>
                  <div className="flex-shrink-0">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          className="btn btn-primary btn-custom-blue"
                          type="button"
                          color="light"
                          onClick={() => handleBack()}
                        >
                          Back
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="card-padding">
                  <div className="live-preview">
                    <div
                      className="table-responsive table-card"
                      style={{ maxHeight: "700px", minHeight: "300px" }}
                    >
                      <table className="table align-middle table-striped table-striped-columns table-bordered table-nowrap border-dark mb-0">
                        <thead
                          className="table-light"
                          style={{
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                          }}
                        >
                          <tr>
                            {staticHeader.map((head, index) => (
                              <th
                                scope="col"
                                key={index}
                                rowSpan={3}
                                className="center"
                              >
                                {head}
                              </th>
                            ))}
                            {vehicleHeaders.map((vehicleName, vehicleIndex) => {
                              // For each vehicle, calculate the colSpan by counting the total versions for all countryGroups
                              const colSpan = tableData.map((data) =>
                                data.vehicleCountryGroup[
                                  vehicleIndex
                                ]?.countryGroupList
                                  .map(
                                    (country) => country.versionOption.length
                                  )
                                  .reduce((acc, curr) => acc + curr, 0)
                              )[0];

                              return (
                                <th
                                  scope="col"
                                  key={vehicleIndex}
                                  colSpan={colSpan}
                                  className="center"
                                >
                                  {vehicleName}
                                </th>
                              );
                            })}
                          </tr>

                          <tr>
                            {countryGroup.map((head) =>
                              head.map((final, index) => (
                                <th
                                  scope="col"
                                  key={index}
                                  colSpan={2}
                                  className="center"
                                >
                                  {final}
                                </th>
                              ))
                            )}
                          </tr>
                          <tr>
                            {countryGroup.map((head) =>
                              head.map((final) =>
                                version.map((head, index) => (
                                  <th
                                    scope="col"
                                    key={index}
                                    className="center"
                                  >
                                    {head}
                                  </th>
                                ))
                              )
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {tableData !== null &&
                          typeof tableData === "object" &&
                          tableData?.length > 0 ? (
                            tableData.map((data, idx) => {
                              const hasMismatch = data.vehicleCountryGroup
                                .flatMap((vehicle, vehicleIdx) =>
                                  vehicle.countryGroupList.flatMap(
                                    (country, countryIdx) => {
                                      const versionOption =
                                        country.versionOption;
                                      // Check if there are exactly two versions to compare
                                      const hasMismatch =
                                        versionOption.length === 2 &&
                                        versionOption[0].option !==
                                          versionOption[1].option;
                                      return hasMismatch;
                                    }
                                  )
                                )
                                .some((val) => val);

                              return (
                                <tr key={idx}>
                                  <td className="center">{data.codeGroup}</td>
                                  <td className="center">{data.code}</td>
                                  <td className="center">
                                    {data.codeDescription}
                                  </td>
                                  <td className="center">{data.Notes}</td>

                                  {data.remarks.map((remark, index) => (
                                    <td key={index} className="center">
                                      {remark.header === "Changes" &&
                                      isEditable && displayChangeStatus &&
                                      hasMismatch ? (
                                        <textarea
                                          className="form-control"
                                          defaultValue={remark.value}
                                          style={{ width: "15vh" }}
                                          onChange={(e) =>
                                            handleChangesChange(
                                              e.target.value,
                                              idx,
                                              "remarks"
                                            )
                                          }
                                        />
                                      ) : (
                                        <span>{remark.value}</span>
                                      )}
                                    </td>
                                  ))}
                                  {data.cr.map((remark, index) => (
                                    <td key={index} className="center">
                                      {remark.header === "Changes" &&
                                      isEditable && displayChangeStatus &&
                                      hasMismatch ? (
                                        <textarea
                                          className="form-control"
                                          defaultValue={remark.value}
                                          style={{ width: "15vh" }}
                                          onChange={(e) =>
                                            handleChangesChange(
                                              e.target.value,
                                              idx,
                                              "cr"
                                            )
                                          }
                                        />
                                      ) : (
                                        <span>{remark.value}</span>
                                      )}
                                    </td>
                                  ))}
                                  {data.cO_KEM.map((remark, index) => (
                                    <td key={index} className="center">
                                      {remark.header === "Changes" &&
                                      isEditable && displayChangeStatus &&
                                      hasMismatch ? (
                                        <textarea
                                          className="form-control"
                                          defaultValue={remark.value}
                                          style={{ width: "15vh" }}
                                          onChange={(e) =>
                                            handleChangesChange(
                                              e.target.value,
                                              idx,
                                              "cO_KEM"
                                            )
                                          }
                                        />
                                      ) : (
                                        <span>{remark.value}</span>
                                      )}
                                    </td>
                                  ))}

                                  {data.vehicleCountryGroup.flatMap(
                                    (vehicle, vehicleIdx) =>
                                      vehicle.countryGroupList.flatMap(
                                        (country, countryIdx) => {
                                          const versionOption =
                                            country.versionOption;
                                          // Check if there are exactly two versions to compare
                                          const hasMismatch =
                                            versionOption.length === 2 &&
                                            versionOption[0].option !==
                                              versionOption[1].option;

                                          return versionOption.map(
                                            (version, versionIdx) => (
                                              <td
                                                key={`${idx}-${vehicleIdx}-${countryIdx}-${versionIdx}`}
                                                className="center"
                                                style={{
                                                  backgroundColor: hasMismatch
                                                    ? "yellow"
                                                    : "transparent", // Highlight mismatches
                                                  color: hasMismatch
                                                    ? "red"
                                                    : "black",
                                                }}
                                              >
                                                {version.version ===
                                                  "Changes" &&
                                                isEditable && displayChangeStatus &&
                                                hasMismatch ? (
                                                  <Select
                                                    className="form-control"
                                                    value={changeDropdown.find(
                                                      (option) =>
                                                        option.value ===
                                                        version.option
                                                    )}
                                                    onChange={(e) =>
                                                      handleSelectChange(e, idx, vehicleIdx, countryIdx, versionIdx)
                                                    }
                                                    options={changeDropdown}
                                                  />
                                                ) : (
                                                  <span>{version.option}</span>
                                                )}
                                              </td>
                                            )
                                          );
                                        }
                                      )
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={6} className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  creatorViewChangeTableData: state.CodeListReducer.creatorViewChangeTableData,
  viewChangesDecisionData: state.CodeListReducer.viewChangesDecisionData,
  loading: state.CodeListReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getViewChangesDecisionAction: (data) =>
    dispatch(getViewChangesDecisionAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewChanges);
