 import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import dashboardCrmSaga from "./dashboardCRM/saga";
import CodeBuilderSaga from "./codeBuilder/saga";
import CodeConfigurationSaga from "./codeConfiguration/saga";
import MasterSaga from "./masters/saga";
import CodeListSaga from "./codeLists/saga";
import AuthenticationSaga from "./authentication/saga";
import UserManagementSaga from "./userManagement/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(dashboardCrmSaga),
    fork(CodeBuilderSaga),
    fork(CodeConfigurationSaga),
    fork(MasterSaga),
    fork(CodeListSaga),
    fork(AuthenticationSaga),
    fork(UserManagementSaga)
  ]);
}
