import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from 'reactstrap';
import { Pagination } from '@mui/material';
import Loader from '../Loader/Loader';
import DeleteModal from '../DeleteModel/DeleteModel';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getAllRoleAction, insertOrUpdateRoleAction, deleteRoleAction } from '../../store/actions';

const RoleCreate = (props: any) => {
    document.title = "RoleCreate";
    const { getAllRole, insertOrUpdateRole, deleteRole, loading } = props;
    const [openModel, setOpenModel] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [todo, setTodo] = useState(null);
    const [editData, setEditData] = useState<any>({});
    const [editId, seteditId] = useState<any>("");
    const [getAllRoleList, setGetAllRoleList] = useState<any>(null);
    const [totalCount, setTotalCount] = useState<any>("");
    const [searchKeyword, setSearchKeyword] = useState("");
    const [sort, setSort] = useState("asc");
    const [currentPage, setCurrentPage] = useState<any>(1);
    const errors: any = {}
    const [formValidation, setFormValiodation] = useState<any>({});
    const recordsPerPage = 10;
    const totalPages = Math.ceil(Number(totalCount) / recordsPerPage);
    const LoginRoleData = sessionStorage.getItem("loginRoleAccess")
    const RoleResponse = JSON.parse(LoginRoleData);
    const Role = RoleResponse?.find(data => data?.name === "UserManagement")?.subModules?.find(val => val?.submenuName === "Role")?.access

    const handleEdit = (role: any) => {
        seteditId(role?.id);
        setOpenModel(true);
        setEditData(role);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleAdd = () => {
        setOpenModel(true);
    }

    const handleSave = () => {
        if (!editData.name) {
            errors.name = 'Please fill the field'
        }
        if (!editData.description) {
            errors.description = 'Please fill the field'
        }
        setFormValiodation(errors);
        const data = {
            id: editId ? editId : 0,
            name: editData.name,
            description: editData.description,
        };
        if (editData.name && editData.description) {
            props.insertOrUpdateRoleAction(data);
            setOpenModel(false);
            handleClear();
        }
    }

    const handleClear = () => {
        setEditData({});
        setFormValiodation({});
        seteditId("");
    }

    const handleDeleteTodo = () => {
        setDeleteModal(false);
        props.deleteRoleAction(todo);
        setCurrentPage(1)
    };

    const onClickTodoDelete = (todo: any) => {
        setTodo(todo);
        setDeleteModal(true);
    };

    const onClickPagination = (event, value) => {
        setCurrentPage(value);
    };

    const onSortChange = (keys: any) => {
        const newSortOrder = sort === 'asc' ? 'desc' : 'asc';
        setSort(newSortOrder);
        props.getAllRoleAction({ page: currentPage, search: searchKeyword, sort: newSortOrder, OrderByColumnName: keys, SortDirection: newSortOrder });
    };

    const onSearchChange = (event: any) => {
        setSearchKeyword(event.target.value);
        props.getAllRoleAction({ page: "1", search: event.target.value, sort: sort })
        setCurrentPage(1);
    };

    useEffect(() => {
        props.getAllRoleAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" });
        setTotalCount(getAllRole?.totalRowsCount);
    }, [currentPage, insertOrUpdateRole, deleteRole])

    useEffect(() => {
        setTotalCount(getAllRole?.totalRowsCount);
    }, [getAllRole])

    useEffect(() => {
        setGetAllRoleList(getAllRole)
    }, [getAllRole])

    return (
        <React.Fragment>
            {loading && <Loader />}
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteTodo()}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Role Create" pageTitle="Masters" />
                    {openModel &&
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader className='card-bg'><h4 className="card-title mb-0 ">Role Create</h4> </CardHeader>
                                    <CardBody>
                                        <div>
                                            <div>
                                                <Row>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Role Name <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter role name" value={editData.name ? editData.name : ""} onChange={(e) => setEditData({ ...editData, name: e.target.value })} />
                                                            <span className='text-validation'>{formValidation.name}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Role Description <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter role description" value={editData.description ? editData.description : ""} onChange={(e) => setEditData({ ...editData, description: e.target.value })} />
                                                            <span className='text-validation'>{formValidation.description}</span>
                                                        </div>
                                                    </Col>

                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end pt-4">
                                                                <Button className="btn btn-primary mb-2 btn-custom-blue" type="button" color='light' onClick={() => handleSave()}>Save</Button>
                                                                <Button className="btn btn-primary me-3 mb-2 btn-custom-blue" type="button" color='light' onClick={handleClear}>Clear</Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex card-bg">
                                    <h4 className="card-title mb-0 flex-grow-1">Role Create List</h4>
                                    <div className="flex-shrink-0">
                                        <div className="form-check form-switch form-switch-right form-switch-md">
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                {Role && Role?.createAccess && <Button className="btn btn-primary btn-custom-blue" type="button" color='light' onClick={() => handleAdd()}>+ Add</Button>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-sm-end">
                                        <div className="search-box ms-2">
                                            <input type="text" className="form-control search" placeholder="Search..." onChange={onSearchChange} />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='card-padding'>
                                    <div className="live-preview">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap table-striped mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No </th>
                                                        <th scope="col">Role Name <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('name')} ></i></th>
                                                        <th scope="col">Role Description <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('description')}></i></th>
                                                        {(Role && Role?.editAccess || Role && Role?.editAccess) && <th scope="col" style={{ width: 50 }}>Action</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getAllRoleList?.dataRows && getAllRoleList?.dataRows?.length > 0 ? (
                                                        getAllRoleList?.dataRows?.map((role: any, index: number) => (
                                                            <tr key={role?.id}>
                                                                <td>{index + 1}</td>
                                                                <td>{role?.name}</td>
                                                                <td>{role?.description}</td>
                                                                {(Role && Role?.editAccess || Role && Role?.editAccess) &&
                                                                    <td>
                                                                        <div className="hstack gap-2">
                                                                            {Role && Role?.editAccess && <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleEdit(role)}>
                                                                                <i className="ri-pencil-fill align-bottom" />
                                                                            </button>}
                                                                            {Role && Role?.deleteAccess && <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete(role?.id)}>
                                                                                <i className="ri-delete-bin-5-fill align-bottom" />
                                                                            </button>}
                                                                        </div>
                                                                    </td>}
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td className='no-data-foun' colSpan={10} >No data found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-none code-view">
                                        <pre className="language-markup" style={{ "height": "275px" }}>
                                            <code>
                                            </code>
                                        </pre>
                                    </div>
                                    {totalCount > 10 &&
                                        <Row className="g-4 mt-3">
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Pagination count={totalPages} page={currentPage} onChange={onClickPagination} shape="rounded" />
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    getAllRole: state.UserManagementReducer.getAllRole,
    insertOrUpdateRole: state.UserManagementReducer.insertOrUpdateRole,
    deleteRole: state.UserManagementReducer.deleteRole,
    loading: state.UserManagementReducer.loading
})

const mapDispatchToProps = (dispatch: any) => ({
    getAllRoleAction: (data: any) => {
        dispatch(getAllRoleAction(data))
    },
    insertOrUpdateRoleAction: (data: any) => {
        dispatch(insertOrUpdateRoleAction(data))
    },
    deleteRoleAction: (data: any) => {
        dispatch(deleteRoleAction(data))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(RoleCreate);
