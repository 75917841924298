import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import Select from "react-select";
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from 'reactstrap';
import { Pagination } from '@mui/material';
import Loader from '../Loader/Loader';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from '../DeleteModel/DeleteModel';
import { getAllVehicleModelAction, getTonnageByVehicleCategoryDropdownAction, getAllCategoriesByVehicleGroupDropdownAction, getAllVehicleGroupByCategoryDropdownAction, insertOrUpdateVehicleModelAction, deleteVehicleModelAction, getAllVehicleGroupDropdownAction } from '../../store/actions';

const VehicleModel = (props: any) => {
    document.title = "VehicleModel";
    const { getAllVehicleModel, getTonnageByVehicleCategoryDropdown, getCategoriesByVehicleGroupDropdown, insertOrUpdateVehicleModel, deleteVehicleModel, getVehicleGroupDropdown, loading } = props;
    const errors: any = {}
    const [formValidation, setFormValiodation] = useState<any>({});
    const [selectedVehicleGroup, setSelectedVehicleGroup] = useState(null);
    const [selectedTonnage, setSelectedTonnage] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [openModel, setOpenModel] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [todo, setTodo] = useState(null);
    const [totalCount, setTotalCount] = useState<any>("");
    const [getAllVehicleModels, setgetAllVehicleModels] = useState<any>(null);
    const [sort, setSort] = useState("");
    const recordsPerPage = 10;
    const totalPages = Math.ceil(Number(totalCount) / recordsPerPage);
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [allTonnageDropdownDatas, setAllTonnageDropdownDatas] = useState<any>(null);
    const [allCategoriesDropdownDatas, setAllCategoriesDropdownDatas] = useState<any>(null);
    const [allVehicleGroupDropdownDatas, setAllVehicleGroupDropdownDatas] = useState<any>(null);
    const [editId, seteditId] = useState<any>("");
    const [editData, setEditData] = useState<any>({});
    const [tonnageId, setTonnageId] = useState<any>("");
    const [categoryId, setCategoryId] = useState<any>("");
    const [vehicleGroupId, setVehicleGroupId] = useState<any>("");
    const LoginRoleData = sessionStorage.getItem("loginRoleAccess")
    const RoleResponse = JSON.parse(LoginRoleData);
    const Role = RoleResponse?.find(data => data?.name === "Master")?.subModules?.find(val => val?.submenuName === "VehicleModel")?.access

    const Tonnage = allTonnageDropdownDatas?.map((item: any) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const Categorie = allCategoriesDropdownDatas?.map((item: any) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const VehicleGroup = allVehicleGroupDropdownDatas?.map((item: any) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const handleEdit = (model: any) => {
        setOpenModel(true);
        setEditData(model);
        setTonnageId(model?.tonnageId);
        setCategoryId(model?.vehicleCategoryId);
        setVehicleGroupId(model?.vehicleGroupId);
        setSelectedVehicleGroup({
            id: model?.vehicleGroupId,
            label: model?.vehicleGroupName,
            value: model?.vehicleGroupName,
            isSelected: model?.isActive,
        });
        setSelectedTonnage({
            id: model?.tonnageId,
            label: model?.tonnageValue,
            value: model?.tonnageValue,
            isSelected: model?.isActive,
        });
        setSelectedCategory({
            id: model?.vehicleCategoryId,
            label: model?.vehicleCategory,
            value: model?.vehicleCategory,
            isSelected: model?.isActive,
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setFormValiodation({});
        seteditId(model?.id);
        props.getAllCategoriesByVehicleGroupDropdownAction(model?.vehicleGroupId);
        props.getAllVehicleGroupByCategoryDropdownAction(model?.vehicleCategory)
    }

    const handleAdd = () => {
        setOpenModel(true);
    }

    const handleSave = () => {
        if (!editData.code) {
            errors.code = 'Please fill the field'
        }
        if (!editData.model) {
            errors.model = 'Please fill the field'
        }
        if (!tonnageId) {
            errors.tonnageId = 'Please select tonnage'
        }
        if (!categoryId) {
            errors.categoryId = 'Please select category'
        }
        if (!vehicleGroupId) {
            errors.vehicleGroupId = 'Please select vehicle group'
        }
        setFormValiodation(errors);
        const models = {
            id: editId ? editId : 0,
            code: editData.code,
            model: editData.model,
            tonnageId: tonnageId,
        };
        setCurrentPage(editId ? currentPage : 1)
        if (editData.code && editData.model && tonnageId && categoryId && vehicleGroupId) {
            props.insertOrUpdateVehicleModelAction(models)
        }
    }

    useEffect(() => {
        if (insertOrUpdateVehicleModel?.payload?.httpStatusCode === 200) {
            setOpenModel(false);
            handleClear();
            props.getAllVehicleModelAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" })
        }
    }, [insertOrUpdateVehicleModel])

    const handleClear = () => {
        setEditData({});
        setTonnageId('');
        setCategoryId('');
        setVehicleGroupId('');
        setSelectedVehicleGroup(null);
        setSelectedTonnage(null);
        setSelectedCategory(null);
        setFormValiodation({});
        seteditId("");
    }

    function handleSelectVehicleGroup(selectedVehicleGroup) {
        setVehicleGroupId(selectedVehicleGroup?.id);
        setSelectedVehicleGroup(selectedVehicleGroup);
        props.getAllCategoriesByVehicleGroupDropdownAction(selectedVehicleGroup?.id)
        setCategoryId('');
        setSelectedCategory(null);
        setTonnageId('');
        setSelectedTonnage(null);
        setAllCategoriesDropdownDatas(null);
        setAllTonnageDropdownDatas(null);
    }

    function handleSelectTonnage(selectedTonnage) {
        setTonnageId(selectedTonnage?.id);
        setSelectedTonnage(selectedTonnage);
    }

    function handleSelectCategory(selectedCategory) {
        setCategoryId(selectedCategory?.id);
        setSelectedCategory(selectedCategory);
        props.getTonnageByVehicleCategoryDropdownAction(selectedCategory?.id)
        setTonnageId('');
        setSelectedTonnage(null);
    }

    const handleDeleteTodo = () => {
        setDeleteModal(false);
        props.deleteVehicleModelAction(todo);
        setCurrentPage(1)
    };

    const onClickPagination = (event, value) => {
        setCurrentPage(value);
    };

    const onClickTodoDelete = (todo) => {
        setTodo(todo);
        setDeleteModal(true);
    };

    const onSortChange = (keys: any) => {
        const newSortOrder = sort === 'asc' ? 'desc' : 'asc';
        setSort(newSortOrder);
        props.getAllVehicleModelAction({ page: currentPage, search: searchKeyword, sort: newSortOrder, OrderByColumnName: keys, SortDirection: newSortOrder });
    };

    const onSearchChange = (event: any) => {
        setSearchKeyword(event.target.value);
        props.getAllVehicleModelAction({ page: "1", search: event.target.value, sort: sort, OrderByColumnName: "" })
        setCurrentPage(1);
    };

    useEffect(() => {
        props.getAllVehicleModelAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" });
        setTotalCount(getAllVehicleModel?.totalRowsCount);
    }, [currentPage, deleteVehicleModel])

    useEffect(() => {
        setTotalCount(getAllVehicleModel?.totalRowsCount);
    }, [getAllVehicleModel])

    useEffect(() => {
        setgetAllVehicleModels(getAllVehicleModel)
    }, [getAllVehicleModel])

    useEffect(() => {
        props.getAllVehicleGroupDropdownAction();
    }, [])

    useEffect(() => {
        setAllVehicleGroupDropdownDatas(getVehicleGroupDropdown?.data)
    }, [getVehicleGroupDropdown])

    useEffect(() => {
        setAllCategoriesDropdownDatas(getCategoriesByVehicleGroupDropdown?.data)
    }, [getCategoriesByVehicleGroupDropdown])

    useEffect(() => {
        setAllTonnageDropdownDatas(getTonnageByVehicleCategoryDropdown?.data)
    }, [getTonnageByVehicleCategoryDropdown])

    return (
        <React.Fragment>
            {loading && <Loader />}
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteTodo()}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Vehicle Model" pageTitle="Masters" />
                    {openModel &&
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader className='card-bg'><h4 className="card-title mb-0 ">Add Vehicle Model</h4> </CardHeader>
                                    <CardBody>
                                        <div>
                                            <div>
                                                <Row>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Vehicle Code <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter vehicle code" value={editData.code ? editData.code : ""} onChange={(e) => setEditData({ ...editData, code: e.target.value })} />
                                                            <span className='text-validation'>{formValidation.code}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Vehicle Model <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter vehicle model" value={editData.model ? editData.model : ""} onChange={(e) => setEditData({ ...editData, model: e.target.value })} />
                                                            <span className='text-validation'>{formValidation.model}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Vehicle Group <span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                isClearable={true}
                                                                value={selectedVehicleGroup}
                                                                onChange={(e) => {
                                                                    handleSelectVehicleGroup(e);
                                                                }}
                                                                options={VehicleGroup}
                                                            />
                                                            <span className='text-validation'>{formValidation.vehicleGroupId}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Vehicle Category <span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                isClearable={true}
                                                                value={selectedCategory}
                                                                onChange={(e) => {
                                                                    handleSelectCategory(e);
                                                                }}
                                                                options={Categorie}
                                                            />
                                                            <span className='text-validation'>{formValidation.categoryId}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Tonnage <span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                isClearable={true}
                                                                value={selectedTonnage}
                                                                onChange={(e) => {
                                                                    handleSelectTonnage(e);
                                                                }}
                                                                options={Tonnage}
                                                            />
                                                            <span className='text-validation'>{formValidation.tonnageId}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3 pt-4">
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                                <Button className="btn btn-primary mb-2 btn-custom-blue" type="button" color='light' onClick={() => handleSave()}>Save</Button>
                                                                <Button className="btn btn-primary me-3 mb-2 btn-custom-blue" type="button" color='light' onClick={handleClear}>Clear</Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex card-bg">
                                    <h4 className="card-title mb-0 flex-grow-1">Vehicle Model List</h4>
                                    <div className="flex-shrink-0">
                                        <div className="form-check form-switch form-switch-right form-switch-md">
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                {Role && Role?.createAccess && <Button className="btn btn-primary btn-custom-blue" type="button" color='light' onClick={() => handleAdd()}>+ Add</Button>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-sm-end">
                                        <div className="search-box ms-2">
                                            <input type="text" className="form-control search" placeholder="Search..." onChange={onSearchChange} />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='card-padding'>
                                    <div className="live-preview">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap table-striped mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No</th>
                                                        <th scope="col">Vehicle Code <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('code')} ></i></th>
                                                        <th scope="col">Vehicle Group <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('vehicleGroupName')} ></i></th>
                                                        <th scope="col">Vehicle Model <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('model')} ></i></th>
                                                        <th scope="col">Vehicle Category <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('vehicleCategory')} ></i></th>
                                                        <th scope="col">Tonnage <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('tonnageValue')} ></i></th>
                                                        {(Role && Role?.editAccess || Role && Role?.deleteAccess) && <th scope="col" style={{ width: 50 }}>Action</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getAllVehicleModels?.dataRows && getAllVehicleModels?.dataRows?.length > 0 ? (
                                                        getAllVehicleModels?.dataRows?.map((model: any, index: number) => (
                                                            <tr key={model?.id}>
                                                                <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>
                                                                <td>{model?.code}</td>
                                                                <td>{model?.vehicleGroupName}</td>
                                                                <td>{model?.model}</td>
                                                                <td>{model?.vehicleCategory}</td>
                                                                <td>{model?.tonnageValue}</td>
                                                                {(Role && Role?.editAccess || Role && Role?.deleteAccess) &&
                                                                    <td>
                                                                        <div className="hstack gap-2">
                                                                            {Role && Role?.editAccess &&
                                                                                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleEdit(model)}>
                                                                                    <i className="ri-pencil-fill align-bottom" />
                                                                                </button>}
                                                                            {Role && Role?.deleteAccess &&
                                                                                <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete(model?.id)}>
                                                                                    <i className="ri-delete-bin-5-fill align-bottom" />
                                                                                </button>}
                                                                        </div>
                                                                    </td>}
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td className='no-data-foun' colSpan={10} >No data found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-none code-view">
                                        <pre className="language-markup" style={{ "height": "275px" }}>
                                            <code>
                                            </code>
                                        </pre>
                                    </div>
                                    {totalCount > 10 &&
                                        <Row className="g-4 mt-3">
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Pagination count={totalPages} page={currentPage} onChange={onClickPagination} shape="rounded" />
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    getAllVehicleModel: state.MasterReducer.getAllVehicleModel,
    getTonnageByVehicleCategoryDropdown: state.MasterReducer.getTonnageByVehicleCategoryDropdown,
    getVehicleGroupDropdown: state.MasterReducer.getVehicleGroupDropdown,
    getCategoriesByVehicleGroupDropdown: state.MasterReducer.getCategoriesByVehicleGroupDropdown,
    getVehicleGroupByCategoryDropdown: state.MasterReducer.getVehicleGroupByCategoryDropdown,
    insertOrUpdateVehicleModel: state.MasterReducer.insertOrUpdateVehicleModel,
    deleteVehicleModel: state.MasterReducer.deleteVehicleModel,
    getLoginData: state.AuthenticationReducer?.getLoginData?.data?.userRoleAccessList,
    loading: state.MasterReducer.loading
})

const mapDispatchToProps = (dispatch: any) => ({
    getAllVehicleModelAction: (data: any) => {
        dispatch(getAllVehicleModelAction(data))
    },
    getTonnageByVehicleCategoryDropdownAction: (data: any) => {
        dispatch(getTonnageByVehicleCategoryDropdownAction(data))
    },

    getAllVehicleGroupDropdownAction: (data: any) => {
        dispatch(getAllVehicleGroupDropdownAction(data))
    },
    getAllCategoriesByVehicleGroupDropdownAction: (data: any) => {
        dispatch(getAllCategoriesByVehicleGroupDropdownAction(data))
    },
    getAllVehicleGroupByCategoryDropdownAction: (data: any) => {
        dispatch(getAllVehicleGroupByCategoryDropdownAction(data))
    },
    insertOrUpdateVehicleModelAction: (data: any) => {
        dispatch(insertOrUpdateVehicleModelAction(data))
    },
    deleteVehicleModelAction: (data: any) => {
        dispatch(deleteVehicleModelAction(data))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(VehicleModel);
