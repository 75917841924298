import { UserManagementActionTypes } from "./actionType";

export const getAllRoleAction = (data) => (
    {
        type: UserManagementActionTypes.GET_ALL_ROLE_REQUEST,
        data
    });

export const getAllRoleSuccess = (data) => {
    return {
        type: UserManagementActionTypes.GET_ALL_ROLE_SUCCESS,
        data
    };
};

export const getAllRoleFailure = (error) => ({
    type: UserManagementActionTypes.GET_ALL_ROLE_FAILED,
    error
});

export const insertOrUpdateRoleAction = (data) => (
    {
        type: UserManagementActionTypes.INSERT_OR_UPDATE_ROLE_REQUEST,
        data
    });

export const insertOrUpdateRoleSuccess = (data) => {
    return {
        type: UserManagementActionTypes.INSERT_OR_UPDATE_ROLE_SUCCESS,
        data
    };
};

export const insertOrUpdateRoleFailure = (error) => ({
    type: UserManagementActionTypes.INSERT_OR_UPDATE_ROLE_FAILED,
    error
});

export const deleteRoleAction = (data) => (
    {
        type: UserManagementActionTypes.DELETE_ROLE_REQUEST,
        data
    });

export const deleteRoleSuccess = (data) => {
    return {
        type: UserManagementActionTypes.DELETE_ROLE_SUCCESS,
        data
    };
};

export const deleteRoleFailure = (error) => ({
    type: UserManagementActionTypes.DELETE_ROLE_FAILED,
    error
});

export const getAllCreateUserAction = (data) => (
    {
        type: UserManagementActionTypes.GET_ALL_CREATE_USER_REQUEST,
        data
    });

export const getAllCreateUserSuccess = (data) => {
    return {
        type: UserManagementActionTypes.GET_ALL_CREATE_USER_SUCCESS,
        data
    };
};

export const getAllCreateUserFailure = (error) => ({
    type: UserManagementActionTypes.GET_ALL_CREATE_USER_FAILED,
    error
});

export const getAllAccessAction = (data) => (
    {
        type: UserManagementActionTypes.GET_ALL_ACCESS_REQUEST,
        data
    });

export const getAllAccessSuccess = (data) => {
    return {
        type: UserManagementActionTypes.GET_ALL_ACCESS_SUCCESS,
        data
    };
};

export const getAllAccessFailure = (error) => ({
    type: UserManagementActionTypes.GET_ALL_ACCESS_FAILED,
    error
});

export const getAllAccessByGroupAction = (data) => (
    {
        type: UserManagementActionTypes.GET_ACCESS_BY_GROUP_REQUEST,
        data
    });

export const getAllAccessByGroupSuccess = (data) => {
    return {
        type: UserManagementActionTypes.GET_ACCESS_BY_GROUP_SUCCESS,
        data
    };
};

export const getAllAccessByGroupFailure = (error) => ({
    type: UserManagementActionTypes.GET_ACCESS_BY_GROUP_FAILED,
    error
});

export const insertOrUpdateAccessAction = (data) => (
    {
        type: UserManagementActionTypes.INSERT_OR_UPDATE_ACCESS_REQUEST,
        data
    });

export const insertOrUpdateAccessSuccess = (data) => {
    return {
        type: UserManagementActionTypes.INSERT_OR_UPDATE_ACCESS_SUCCESS,
        data
    };
};

export const insertOrUpdateAccessFailure = (error) => ({
    type: UserManagementActionTypes.INSERT_OR_UPDATE_ACCESS_FAILED,
    error
});

export const deleteAccessAction = (data) => (
    {
        type: UserManagementActionTypes.DELETE_ACCESS_REQUEST,
        data
    });

export const deleteAccessSuccess = (data) => {
    return {
        type: UserManagementActionTypes.DELETE_ACCESS_SUCCESS,
        data
    };
};

export const deleteAccessFailure = (error) => ({
    type: UserManagementActionTypes.DELETE_ACCESS_FAILED,
    error
});

export const getAllUserDropDownAction = (data) => (
    {
        type: UserManagementActionTypes.GET_ALL_USER_DROP_DOWN_REQUEST,
        data
    });

export const getAllUserDropDownSuccess = (data) => {
    return {
        type: UserManagementActionTypes.GET_ALL_USER_DROP_DOWN_SUCCESS,
        data
    };
};

export const getAllUserDropDownFailure = (error) => ({
    type: UserManagementActionTypes.GET_ALL_USER_DROP_DOWN_FAILED,
    error
});

export const insertOrUpdateCreateUserAction = (data) => (
    {
        type: UserManagementActionTypes.INSERT_OR_UPDATE_CREATE_USER_REQUEST,
        data
    });

export const insertOrUpdateCreateUserSuccess = (data) => {
    return {
        type: UserManagementActionTypes.INSERT_OR_UPDATE_CREATE_USER_SUCCESS,
        data
    };
};

export const insertOrUpdateCreateUserFailure = (error) => ({
    type: UserManagementActionTypes.INSERT_OR_UPDATE_CREATE_USER_FAILED,
    error
});

export const getRollAccessMappingDropDownAction = (data) => (
    {
        type: UserManagementActionTypes.GET_ROLE_ACCESS_MAPPING_DROP_DOWN_REQUEST,
        data
    });

export const getRollAccessMappingDropDownSuccess = (data) => {
    return {
        type: UserManagementActionTypes.GET_ROLE_ACCESS_MAPPING_DROP_DOWN_SUCCESS,
        data
    };
};

export const getRollAccessMappingDropDownFailure = (error) => ({
    type: UserManagementActionTypes.GET_ROLE_ACCESS_MAPPING_DROP_DOWN_FAILED,
    error
});

export const insertOrUpdateRoleAccessMappingAction = (data) => (
    {
        type: UserManagementActionTypes.INSERT_OR_UPDATE_ROLE_ACCESS_MAPPING_REQUEST,
        data
    });

export const insertOrUpdateRoleAccessMappingSuccess = (data) => {
    return {
        type: UserManagementActionTypes.INSERT_OR_UPDATE_ROLE_ACCESS_MAPPING_SUCCESS,
        data
    };
};

export const insertOrUpdateRoleAccessMappingFailure = (error) => ({
    type: UserManagementActionTypes.INSERT_OR_UPDATE_ROLE_ACCESS_MAPPING_FAILED,
    error
}); 

export const getRoleAccessMappingAction = (data) => (
    {
        type: UserManagementActionTypes.GET_ROLE_ACCESS_MAPPING_REQUEST,
        data
    });

export const getRoleAccessMappingSuccess = (data) => {
    return {
        type: UserManagementActionTypes.GET_ROLE_ACCESS_MAPPING_SUCCESS,
        data
    };
};

export const getRoleAccessMappingFailure = (error) => ({
    type: UserManagementActionTypes.GET_ROLE_ACCESS_MAPPING_FAILED,
    error
});
