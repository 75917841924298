import { takeEvery, call, put } from "redux-saga/effects";
import { baseAPI } from "../../helpers/api_helper.js"
import { toast } from 'react-toastify';
import { searchBooleanLogicSuccess, searchBooleanLogicFailure, modifidedCombinationSuccess, modifidedCombinationFailure, getBauMasterDataSuccess, getBauMasterDataFailure, getSanCheckOldcodeSuccess, getSanCheckOldcodeFailure, getSanCheckVarientSuccess, getSanCheckVarientFailure, sanCheckExcelExportSuccess, sanCheckExcelExportFailure, getCodesDropDownSuccess, getCodesDropDownFailure, getSelectedCodeTruthTableSuccess, getSelectedCodeTruthTableFailure, getBooleanLogicCheckSuccess, getBooleanLogicCheckFailure,getBooleanLogicCodeGenerationSuccess,getBooleanLogicCodeGenerationFailure, getCodeBuilderVehicleGroupSuccess, getCodeBuilderVehicleGroupFailure } from "./action";
import { CodeBuilderActionTypes } from "./actionType";
import { ConfigAPI } from "../../helpers/url_helper.js";

// Get All the budget by project id
function* workerGetAllInputBooleanDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INPUT_BOOLEAN_LOGIC_SEARCH;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(searchBooleanLogicSuccess({
        payload: data,
        data: data.data,
      }))
      
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(searchBooleanLogicFailure(data))
    }
  } catch (error) {
    yield put(searchBooleanLogicFailure(error))
  }
}

function* workerGetModifidedCombinationDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_MODIFIDED_COMBINATION_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(modifidedCombinationSuccess({
        payload: data,
        data: data.data,
      }))
      
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(modifidedCombinationFailure(data))
    }
  } catch (error) {
    yield put(modifidedCombinationFailure(error))
  }
}

function* workerGetBauMasterDataDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.GET_BAU_MASTER_DATA_URL}?vehicleGroupId=${request}`;
    const { data } = yield call(baseAPI.get, `${uri}`);
    if (data.httpStatusCode === 200) {
      yield put(getBauMasterDataSuccess({
        payload: data,
        data: data.data,
      }))
      
    } else {
      yield put(getBauMasterDataFailure(data))
    }
  } catch (error) {
    yield put(getBauMasterDataFailure(error))
  }
}

function* workerGetsanCheckOldCodeDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_SANCHECK_OLDCODE_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getSanCheckOldcodeSuccess({
        payload: data,
        data: data.data,
      }))
      
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getSanCheckOldcodeFailure(data))
    }
  } catch (error) {
    yield put(getSanCheckOldcodeFailure(error))
  }
}

function* workerGetsanCheckVarientDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_SANCHECK_VARIENT_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getSanCheckVarientSuccess({
        payload: data,
        data: data.data,
      }))
    } else if (data.httpStatusCode === 204) {
      toast.warning(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getSanCheckVarientSuccess({
        payload: data,
        data: data.data,
      }))
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getSanCheckVarientFailure(data))
    }
  } catch (error) {
    yield put(getSanCheckVarientFailure(error))
  }
}

function* workerSanCheckExcelExportDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.SANCHECK_EXCELEXPORT_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(sanCheckExcelExportSuccess({
        payload: data,
        data: data.data,
      }))
      
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(sanCheckExcelExportFailure(data))
    }
  } catch (error) {
    yield put(sanCheckExcelExportFailure(error))
  }
}

function* workerGetCodesDropdownDataDetails(action) {
  const {searchQuery, vehicleGroupId} = action.data;
  try {
    const uri = `${ConfigAPI.GET_CODES_DROPDOWN_URL}${searchQuery}&vehicleGroupId=${vehicleGroupId}`;
    const { data } = yield call(baseAPI.get, `${uri}`);
    if (data.httpStatusCode === 200) {
      // toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getCodesDropDownSuccess({
        payload: data,
        data: data.data,
      })) 
      
    } else {
      // toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getCodesDropDownFailure(data))
    }
  } catch (error) {
    yield put(getCodesDropDownFailure(error))
  }
}

function* workerGetSelectedCodeTruthtableDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_SELECTED_CODE_TRUTHTABLE_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getSelectedCodeTruthTableSuccess({
        payload: data,
        data: data.data,
      }))
      
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getSelectedCodeTruthTableFailure(data))
    }
  } catch (error) {
    yield put(getSelectedCodeTruthTableFailure(error))
  }
}

function* workerGetBooleanLogicLogicCheckDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_BOOLEANLOGIC_LOGICCHECK_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });      
      yield put(getBooleanLogicCheckSuccess({
        payload: data,
        data: data.data,
      }))
      
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getBooleanLogicCheckFailure(data))
    }
  }
  catch (error) {
    yield put(getBooleanLogicCheckFailure(error))
  }
}

function* workerGetBooleanLogicCodeGenerationDetails(action) {
  const request = action.data;
  try {
    // const uri = ConfigAPI.GET_BOOLEANLOGIC_CODEBUILDER_DATA_URL;
    const uri = `${ConfigAPI.GET_BOOLEANLOGIC_CODEBUILDER_DATA_URL}`;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });      
      yield put(getBooleanLogicCodeGenerationSuccess({
        payload: data,
        data: data.data,
      }))
      
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getBooleanLogicCodeGenerationFailure(data))
    }
  }
  catch (error) {
    yield put(getBooleanLogicCodeGenerationFailure(error))
  }
}

function* workerGetCodeBuilderVehicleGroupDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_CODEBUILDER_VEHICLE_GROUP_URL;
    const { data } = yield call(baseAPI.get, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      // toast.success(data.httpStatusMessage, { position: 'bottom-right' });      
      yield put(getCodeBuilderVehicleGroupSuccess({
        payload: data,
        data: data.data,
      }))
      
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getCodeBuilderVehicleGroupFailure(data))
    }
  }
  catch (error) {
    yield put(getCodeBuilderVehicleGroupFailure(error))
  }
}


function* CodeBuilderSaga() {
  yield takeEvery(CodeBuilderActionTypes.UPDATE_INPUT_BOOLEAN_REQUEST, workerGetAllInputBooleanDetails);
  yield takeEvery(CodeBuilderActionTypes.GET_MODIFIDED_COMBINATION_REQUEST, workerGetModifidedCombinationDetails);
  yield takeEvery(CodeBuilderActionTypes.GET_BAU_MASTER_DATA_REQUEST, workerGetBauMasterDataDetails);
  yield takeEvery(CodeBuilderActionTypes.GET_SANCHECK_OLDCODE_REQUEST, workerGetsanCheckOldCodeDetails);
  yield takeEvery(CodeBuilderActionTypes.GET_SANCHECK_GET_VARIENT_REQUEST, workerGetsanCheckVarientDetails);
  yield takeEvery(CodeBuilderActionTypes.SANCHECK_EXCELEXPORT_REQUEST, workerSanCheckExcelExportDetails);
  yield takeEvery(CodeBuilderActionTypes.GET_CODES_DROPDOWN_REQUEST, workerGetCodesDropdownDataDetails);
  yield takeEvery(CodeBuilderActionTypes.GET_SELECTED_CODE_TRUTHTABLE_REQUEST, workerGetSelectedCodeTruthtableDetails);
  yield takeEvery(CodeBuilderActionTypes.GET_BOOLEANLOGIC_LOGICCHECK_REQUEST, workerGetBooleanLogicLogicCheckDetails);
  yield takeEvery(CodeBuilderActionTypes.GET_BOOLEANLOGIC_CODEGENERATION_REQUEST, workerGetBooleanLogicCodeGenerationDetails);
  yield takeEvery(CodeBuilderActionTypes.GET_CODEBUILDER_VEHICLE_GROUP_REQUEST, workerGetCodeBuilderVehicleGroupDetails);
  
}

export default CodeBuilderSaga;