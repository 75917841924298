module.exports = {
  api: {
    // API_URL: "http://192.168.123.114:9090",
    //  API_URL: "https://localhost:7224",
    //  API_URL: "https://evt-api-dev.izserver8.in",
    API_URL: process.env.REACT_APP_API_PATH
    // API_URL: "https://evt-api-uat.izserver8.in",
    // API_LOG_URL: "https://evt-api-uat.izserver8.in"
    // API_LOG_URL: "https://api-node.themesbrand.website"
    // API_LOG_URL: "https://evt-api-uat.izserver8.in"
  }
};