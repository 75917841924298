import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { connect } from "react-redux";
import Select from "react-select";
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from 'reactstrap';
import { Pagination } from '@mui/material';
import Loader from '../Loader/Loader';
import DeleteModal from '../DeleteModel/DeleteModel';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getAllAccessAction, getAllAccessByGroupAction, insertOrUpdateAccessAction, deleteAccessAction } from '../../store/actions';

const Access = (props: any) => {
    document.title = "Access";
    const { getAllAccess, getAllAccessByGroup, insertOrUpdateAccess, deleteAccess, loading } = props;
    const errors: any = {}
    const [formValidation, setFormValiodation] = useState<any>({});
    const [selectedGroup2, setSelectedGroup2] = useState(null);
    const [openModel, setOpenModel] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [todo, setTodo] = useState(null);
    const [getAllAccessByGroupData, setGetAllAccessByGroupData] = useState<any>(null);
    const [editId, seteditId] = useState<any>("");
    const [editData, setEditData] = useState<any>({});
    const [groupList, setGroupList] = useState<any>("");
    const [getAllAccessList, setGetAllAccessList] = useState<any>(null);
    const [totalCount, setTotalCount] = useState<any>("");
    const [searchKeyword, setSearchKeyword] = useState("");
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [sort, setSort] = useState("asc");
    const recordsPerPage = 10;
    const totalPages = Math.ceil(Number(totalCount) / recordsPerPage);
    const LoginRoleData = sessionStorage.getItem("loginRoleAccess")
    const RoleResponse = JSON.parse(LoginRoleData);
    const Role = RoleResponse?.find(data => data?.name === "UserManagement")?.subModules?.find(val => val?.submenuName === "Access")?.access

    const Group = getAllAccessByGroupData?.map((item: any) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const handleEdit = (access: any) => {
        setGroupList(access?.groupId);
        seteditId(access?.id);
        setSelectedGroup2({
            id: access?.groupId,
            label: access?.groupName,
            value: access?.groupName,
            isSelected: access?.isActive,
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setOpenModel(true);
        setEditData(access)
    }

    const handleAdd = () => {
        setOpenModel(true);
    }

    const handleSave = () => {
        if (!editData.name) {
            errors.name = 'Please fill the field'
        }
        if (!editData.description) {
            errors.description = 'Please fill the field'
        }
        if (!groupList) {
            errors.groupList = 'Please select vehicle group'
        }
        const vehicle = {
            id: editId ? editId : 0,
            groupId: groupList,
            name: editData.name,
            description: editData.description,
        };
        setFormValiodation(errors);
        if (editData.name && editData.description && groupList) {
            props.insertOrUpdateAccessAction(vehicle);
        }
    }

    function handleSelectGroups2(selectedGroup2) {
        setGroupList(selectedGroup2?.id)
        setSelectedGroup2(selectedGroup2);
    }

    const handleDeleteTodo = () => {
        props.deleteAccessAction(todo);
        setDeleteModal(false);
        setCurrentPage(1);
    };

    const onClickTodoDelete = (todo: any) => {
        setTodo(todo);
        setDeleteModal(true);
    };

    const onClickPagination = (event, value) => {
        setCurrentPage(value);
    };

    const onSearchChange = (event) => {
        setSearchKeyword(event.target.value);
        props.getAllAccessAction({ page: "1", search: event.target.value, sort: sort })
        setCurrentPage(1);
    };

    const onSortChange = (keys: any) => {
        const newSortOrder = sort === 'asc' ? 'desc' : 'asc';
        setSort(newSortOrder);
        props.getAllAccessAction({ page: currentPage, search: searchKeyword, sort: newSortOrder, OrderByColumnName: keys, SortDirection: newSortOrder });
    };

    useEffect(() => {
        if (insertOrUpdateAccess?.httpStatusCode === 200) {
            setOpenModel(false);
            handleClear();
            props.getAllAccessAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" })
        }
    }, [insertOrUpdateAccess])

    const handleClear = () => {
        setEditData({});
        setGroupList('');
        setSelectedGroup2(null);
        setFormValiodation({});
        seteditId("");
    }

    useEffect(() => {
        props.getAllAccessByGroupAction()
    }, [])

    useEffect(() => {
        setGetAllAccessByGroupData(getAllAccessByGroup?.accessGroupList)
    }, [getAllAccessByGroup])

    useEffect(() => {
        setTotalCount(getAllAccess?.totalRowsCount);
    }, [getAllAccess])

    useEffect(() => {
        props.getAllAccessAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" });
        setTotalCount(getAllAccess?.totalRowsCount);
    }, [currentPage, deleteAccess]);

    useEffect(() => {
        setGetAllAccessList(getAllAccess)
    }, [getAllAccess])

    return (
        <React.Fragment>
            {loading && <Loader />}
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteTodo()}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Access" pageTitle="Masters" />
                    {openModel &&
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader className='card-bg'><h4 className="card-title mb-0 ">Access</h4> </CardHeader>
                                    <CardBody>
                                        <div>
                                            <div>
                                                <Row>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Access Name <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter access name" value={editData.name ? editData.name : ""} onChange={(e) => setEditData({ ...editData, name: e.target.value })} />
                                                            <span className='text-validation'>{formValidation.name}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Access Description <span style={{ color: 'red' }}>*</span></Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Enter access description" value={editData.description ? editData.description : ""} onChange={(e) => setEditData({ ...editData, description: e.target.value })} />
                                                            <span className='text-validation'>{formValidation.description}</span>
                                                        </div>
                                                    </Col>

                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Access Group <span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                isClearable={true}
                                                                value={selectedGroup2}
                                                                onChange={(e) => {
                                                                    handleSelectGroups2(e);
                                                                }}
                                                                options={Group}
                                                            />
                                                            <span className='text-validation'>{formValidation.groupList}</span>
                                                        </div>
                                                    </Col>
                                                    <div>
                                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end pt-4">
                                                            <Button className="btn btn-primary mb-2 btn-custom-blue" type="button" color='light' onClick={() => handleSave()}>Save</Button>
                                                            <Button className="btn btn-primary me-3 mb-2 btn-custom-blue" type="button" color='light' onClick={handleClear}>Clear</Button>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>

                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex card-bg">
                                    <h4 className="card-title mb-0 flex-grow-1">Access List</h4>
                                    <div className="flex-shrink-0">
                                        <div className="form-check form-switch form-switch-right form-switch-md">
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                {Role && Role?.createAccess && <Button className="btn btn-primary btn-custom-blue" type="button" color='light' onClick={() => handleAdd()}>+ Add</Button>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-sm-end">
                                        <div className="search-box ms-2">
                                            <input type="text" className="form-control search" placeholder="Search..." onChange={onSearchChange} />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='card-padding'>
                                    <div className="live-preview">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No </th>
                                                        <th scope="col">Access Name <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('name')} ></i></th>
                                                        <th scope="col">Access Description <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('description')} ></i></th>
                                                        {(Role && Role?.editAccess || Role && Role?.editAccess) && <th scope="col" style={{ width: 50 }}>Action</th>}
                                                    </tr>
                                                </thead>
                                                <tbody> {getAllAccessList?.dataRows && getAllAccessList?.dataRows?.length > 0 ? (
                                                    getAllAccessList?.dataRows?.map((access: any, index: number) => (
                                                        <tr key={access?.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{access?.name}</td>
                                                            <td>{access?.description}</td>
                                                            {(Role && Role?.editAccess || Role && Role?.editAccess) &&
                                                                <td>
                                                                    <div className="hstack gap-2">
                                                                        {Role && Role?.editAccess && <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleEdit(access)}>
                                                                            <i className="ri-pencil-fill align-bottom" />
                                                                        </button>}
                                                                        {Role && Role?.deleteAccess && <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete(access?.id)}>
                                                                            <i className="ri-delete-bin-5-fill align-bottom" />
                                                                        </button>}
                                                                    </div>
                                                                </td>
                                                            }
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td className='no-data-foun' colSpan={10} >No data found</td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-none code-view">
                                        <pre className="language-markup" style={{ "height": "275px" }}>
                                            <code>
                                            </code>
                                        </pre>
                                    </div>
                                    {totalCount > 10 &&
                                        <Row className="g-4 mt-3">
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Pagination count={totalPages} page={currentPage} onChange={onClickPagination} shape="rounded" />
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    getAllAccess: state.UserManagementReducer.getAllAccess,
    getAllAccessByGroup: state.UserManagementReducer.getAllAccessByGroup,
    insertOrUpdateAccess: state.UserManagementReducer.insertOrUpdateAccess,
    deleteAccess: state.UserManagementReducer.deleteAccess,
    loading: state.UserManagementReducer.loading
})

const mapDispatchToProps = (dispatch: any) => ({
    getAllAccessAction: (data: any) => {
        dispatch(getAllAccessAction(data))
    },
    getAllAccessByGroupAction: (data: any) => {
        dispatch(getAllAccessByGroupAction(data))
    },
    insertOrUpdateAccessAction: (data: any) => {
        dispatch(insertOrUpdateAccessAction(data))
    },
    deleteAccessAction: (data: any) => {
        dispatch(deleteAccessAction(data))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Access);
