import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { searchBooleanLogicAction, modifidedCombinationAction, initCodeBuilderModule } from "../../store/actions";
import {
  Input,
  Button,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Card,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import Loader from "../Loader/Loader";
import "./additionalStyle.css";

const CodeRuleGenerator = (props: any) => {

  document.title = "Code Rule Generator";

  const { inputBooleanResponse, getModifidedCombination, loading } = props;
  
  const history = useNavigate();
  const [inputDecoder, setInputDecoder] = useState<string>("");
  const [formValidation, setFormValidation] = useState<any>({});
  const [booleanLogicCodeCombination, setBooleanLogicCodeCombination] = useState<any>(null);
  const [booleanModifiedCombination, setBooleanModifiedCombination] = useState<any>(null);
  const [truthTableModel, setTruthTableModel] = useState(false);
  const [truthTableHeaders, setTruthTableHeaders] = useState<string[]>([]);
  const [checkedItems, setCheckedItems] = useState<boolean[]>(
    booleanLogicCodeCombination?.booleanLogicTruthTable?.truthTableRows?.map(() => false) || []
  );

  useEffect(() => {
    props.initCodeBuilderModule();
  },[])
  
  useEffect(() => {
    setBooleanLogicCodeCombination(inputBooleanResponse);
    setCheckedItems(inputBooleanResponse?.booleanLogicTruthTable?.truthTableRows?.map(() => false) || []);
  }, [inputBooleanResponse]);

  useEffect(() => {
    setBooleanModifiedCombination(getModifidedCombination);
  },[getModifidedCombination])

  useEffect(() => {
    if(inputBooleanResponse) {
      setTruthTableHeaders(inputBooleanResponse.booleanLogicTruthTable?.truthTableHeaders || []);
    } 
  }, [inputBooleanResponse])

  const handleDecoder = () => {
    const deCodeValue = inputDecoder.trim();
    if (!deCodeValue) {
      setFormValidation({ deCoder: "Please fill the field" });
    } else {
      setFormValidation({});
      const requestData = { booleanLogic: inputDecoder };
      props.searchBooleanLogicAction(requestData);
    }
  };

  const handleSanCheckScreen  = () => {
    history("/san-check");
  }

  const handleCheckboxChange = (index: number) => {
    setCheckedItems(prevCheckedItems => {
      const newCheckedItems = [...prevCheckedItems];
      newCheckedItems[index] = !newCheckedItems[index];
      return newCheckedItems;
    });
  };

  const handleCodeCombination = () => {
    history("/code-combination")
  }

  const getFormattedData = () => {
    return booleanLogicCodeCombination?.booleanLogicTruthTable?.truthTableRows?.map((item, index) => ({
      explanation: item.explanation,
      values: item.values,
      isRequired: checkedItems[index] ? true : false,
    }));
  };

  const handleTruthTableModel = () => {
    setTruthTableModel(prev => !prev);
  };

  const handleEncodeLogic = () => {
    const formattedData = getFormattedData();
    const filteredData = formattedData?.filter(item => item?.isRequired === true) || [];
    
    if(filteredData?.length > 0) {
      props.modifidedCombinationAction({truthTableHeaders, truthTableRows: filteredData});
    }
  };

  const SplitCodeCombinations = ({ data }: { data: any[] }) => {
    const handleCodeSplit = (codeCombination: string) => {
      return codeCombination
        .split(/(?=\+)/)
        .map(segment => segment.trim())
        .filter(Boolean);
    };

    const getBackgroundColor = (segment: string) => {
      return segment.startsWith("+-")
        ? "btn btn-soft-danger btn-border-danger"
        : "btn btn-soft-success btn-border-success";
    };

    const splitSegment = (segment: string) => {
      const parts = [];
      let temp = "";
      for (let i = 0; i < segment.length; i++) {
        if (segment[i] === "+" && segment[i + 1] === "-") {
          if (temp) parts.push(temp);
          parts.push("+-");
          temp = "";
          i++;
        } else if (segment[i] === "+" || segment[i] === "-") {
          if (temp) parts.push(temp);
          parts.push(segment[i]);
          temp = "";
        } else {
          temp += segment[i];
        }
      }
      if (temp) parts.push(temp);
      return parts;
    };

    return (
      <div>
        {data?.map((item, index) => {
          const segments = handleCodeSplit(item?.codeCombination || "");
          return (
            <div key={index} className="mb-1">
              <Card className="mb-1">
                <CardBody className="p-2">
                  <div className="flex-grow-1 ms-3 shadow-none">
                    <div className="mt-2 d-flex">
                      {segments.map((segment, idx) => (
                        <div key={idx} className="p-0 my-1">
                          {splitSegment(segment).map((part, partIdx) => (
                            <React.Fragment key={partIdx}>
                              {part.match(/[\+\-\+\-]/) ? (
                                <span className="me-3 mx-3 font-strenth">
                                  {part}
                                </span>
                              ) : (
                                <span
                                  style={{ cursor: "default" }}
                                  className={`rounded px-2 px-1 ${getBackgroundColor(segment)}`}
                                >
                                  {part}
                                </span>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                    <p className="text-muted mb-0">
                      {item?.codeCombinationDescription}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Code Rule Generator" pageTitle="Code Builder" />
          <Row>
            <Col xl={2}></Col>
            <Col xl={8}>
              <Card>
                <CardHeader className="align-items-center d-flex p-2">
                  <h4 className="card-title mb-0 flex-grow-1">Input Boolean Logic</h4>
                </CardHeader>
                <CardBody>
                  <div className="live-preview p-2">
                    <div className="table-responsive table-card">
                      <Row className="align-items-center g-3 w-100">
                        <div className="input-group">
                          <Input
                            type="text"
                            value={inputDecoder}
                            className="form-control"
                            onChange={({ target }) => setInputDecoder(target.value)}
                          />
                          <span>
                            <Button
                              className="input-group-text btn btn-primary btn-custom-blue me-2"
                              onClick={handleDecoder}
                              type="submit"
                            >
                              Decode
                            </Button>
                          </span>
                          <span>
                            <Button
                              className="input-group-text btn btn-primary btn-custom-blue"
                              onClick={handleCodeCombination}
                              type="submit"
                            >
                              Code Combination
                            </Button>
                          </span>
                        </div>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={2}></Col>
            {booleanLogicCodeCombination?.booleanLogicTruthTable?.truthTableHeaders?.length > 0 && (
              <Col xl={12}>
                <Card>
                  <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">
                      Boolean Logic Combination with Description
                    </h4>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <Button
                        className="btn btn-primary me-3 mb-2 btn-custom-blue"
                        type="button"
                        onClick={() => handleTruthTableModel()}
                      >
                        Generate Truth Table
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody className="card-padding">
                    <SimpleBar className="pe-2 me-n1" style={{ minHeight: "100px" }}>
                      <SplitCodeCombinations
                        data={booleanLogicCodeCombination?.booleanLogicCodeCombination}
                      />
                    </SimpleBar>
                  </CardBody>
                </Card>
                {truthTableModel && (
                  <Card>
                    <CardHeader className="align-items-center d-flex p-2">
                      <h4 className="card-title mb-0 flex-grow-1">Truth Table</h4>
                    </CardHeader>
                    <CardBody className="card-padding">
                      <div className="live-preview">
                        <div className="table-responsive table-card" style={{ maxHeight: "600px" }}>
                          <table className="table align-middle table-nowrap table-striped-columns mb-0">
                            <thead className="table-light">
                              <tr>
                                {booleanLogicCodeCombination?.booleanLogicTruthTable?.truthTableHeaders?.map((header, index) => (
                                  <th scope="col" key={index}>{header}</th>
                                ))}
                                <th scope="col">Explanation</th>
                                <th scope="col" style={{ width: "10px" }}>Required</th>
                              </tr>
                            </thead>
                            <tbody>
                              {booleanLogicCodeCombination?.booleanLogicTruthTable?.truthTableRows?.map((row, index) => (
                                <tr key={index}>
                                  {row.values.map((val, i) => (
                                    <td style={{ width: "60px" }} key={i}>{val}</td>
                                  ))}
                                  <td className="center-content">
                                    <Input
                                      value={row.explanation}
                                      id={`input-${index}`}
                                      name={`input-${index}`}
                                      className="form-control"
                                      type="text"
                                      required
                                      autoComplete="off"
                                    />
                                  </td>
                                  <td className="center-content">
                                    <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Input
                                        className="form-check-input code-switcher"
                                        type="checkbox"
                                        checked={checkedItems[index]}
                                        onChange={() => handleCheckboxChange(index)}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end"> 
                        <Button
                          className="btn btn-primary me-3 mb-2 btn-custom-blue"
                          type="button"
                          color="light"
                          onClick={handleEncodeLogic}
                        >
                          Encode Logic
                        </Button>
                      </div>
                  </Card> 
                )}
                {booleanModifiedCombination?.modifiedTruthCodeCombination?.length > 0 && 
                <>
                 <Card>
                 <CardHeader className="align-items-center d-flex">
                   <h4 className="card-title mb-0 flex-grow-1">
                     Modified Combination with Description
                   </h4>
                 </CardHeader>
                 <CardBody className="card-padding">
                   <SimpleBar className="pe-2 me-n1" style={{ minHeight: "100px" }}>
                     <SplitCodeCombinations
                       data={booleanModifiedCombination?.modifiedTruthCodeCombination}
                     />
                   </SimpleBar>
                 </CardBody>
               </Card>
               <Col xl={12}>
               <Card>
                 <CardHeader className="align-items-center d-flex p-2">
                   <h4 className="card-title mb-0 flex-grow-1">Re-Formed Boolean Logic</h4>
                 </CardHeader>
                 <CardBody>
                   <div className="live-preview p-2">
                     <div className="table-responsive table-card">
                       <Row className="align-items-center g-3 w-100">
                         <div className="input-group">
                           <Input
                             type="text"
                             value={booleanModifiedCombination?.reformationBooleanLogic}
                             className="form-control"
                             onChange={({ target }) => setInputDecoder(target.value)}
                           />
                         <Button
                          className="btn btn-primary btn-custom-blue"
                          type="button"
                          color="light"
                          onClick={handleSanCheckScreen}
                        >
                          Sancheck
                        </Button>
                         </div>
                         <span className="text-validation">{formValidation.deCoder}</span>
                       </Row>
                     </div>
                   </div>
                 </CardBody>
               </Card>
             </Col>
               </>
                }
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  inputBooleanResponse: state?.CodeBuilderReducer?.inputBooleanResponse,
  getModifidedCombination: state?.CodeBuilderReducer?.getModifidedCombination,
  loading: state?.CodeBuilderReducer?.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  searchBooleanLogicAction: (data: any) => dispatch(searchBooleanLogicAction(data)),
  modifidedCombinationAction: (data: any) => dispatch(modifidedCombinationAction(data)),
  initCodeBuilderModule: (data: any) => dispatch(initCodeBuilderModule(data)),
  
});

export default connect(mapStateToProps, mapDispatchToProps)(CodeRuleGenerator);
