import { takeEvery, call, put } from "redux-saga/effects";
import { baseAPI } from "../../helpers/api_helper.js"
import { toast } from 'react-toastify';
import { getAllRoleSuccess, getAllRoleFailure, insertOrUpdateRoleSuccess, insertOrUpdateRoleFailure, deleteRoleSuccess, deleteRoleFailure, getAllCreateUserSuccess, getAllCreateUserFailure, getAllAccessSuccess, getAllAccessFailure, getAllAccessByGroupSuccess, getAllAccessByGroupFailure, insertOrUpdateAccessSuccess, insertOrUpdateAccessFailure, deleteAccessSuccess, deleteAccessFailure, getAllUserDropDownSuccess,getAllUserDropDownFailure, insertOrUpdateCreateUserSuccess, insertOrUpdateCreateUserFailure, getRollAccessMappingDropDownSuccess, getRollAccessMappingDropDownFailure, insertOrUpdateRoleAccessMappingSuccess, insertOrUpdateRoleAccessMappingFailure, getRoleAccessMappingSuccess, getRoleAccessMappingFailure} from "./action";
import { UserManagementActionTypes } from "./actionType";
import { ConfigAPI } from "../../helpers/url_helper";

// Get All the budget by project id
function* workerGetAllRoleDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_ROLE_URL}?OrderByColumnName=${OrderByColumnName}&PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      // toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getAllRoleSuccess({
        payload: data,
        data: data.data,
      }))
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getAllRoleFailure(data))
    }
  } catch (error) {
    yield put(getAllRoleFailure(error))
  }
}

function* workerInsertOrUpdateRoleDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_ROLE_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(insertOrUpdateRoleSuccess({
        payload: data,
        data: data.data,
      }))
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(insertOrUpdateRoleFailure(data))
    }
  } catch (error) {
    yield put(insertOrUpdateRoleFailure(error))
  }
}

function* workerDeleteRoleDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.DELETE_ROLE_URL}?roleId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(deleteRoleSuccess({
        payload: data,
        data: data.data,
      }))
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(deleteRoleFailure(data))
    }
  } catch (error) {
    yield put(deleteRoleFailure(error))
  }
}

function* workerGetAllCreateUser(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_CREATE_USER}?OrderByColumnName=${OrderByColumnName}&PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      // toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getAllCreateUserSuccess({
        payload: data,
        data: data.data,
      }))
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getAllCreateUserFailure(data))
    }
  } catch (error) {
    yield put(getAllCreateUserFailure(error))
  }
}

function* workerGetAllAccessDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.GET_ALL_ACCESS_URL}?OrderByColumnName=${OrderByColumnName}&PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      // toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getAllAccessSuccess({
        payload: data,
        data: data.data,
      }))
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getAllAccessFailure(data))
    }
  } catch (error) {
    yield put(getAllAccessFailure(error))
  }
}

function* workerGetAllAccessByGroupDetails(action) {
  try {
    const uri = `${ConfigAPI.GET_ACCESS_BY_GROUP_URL}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      // toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getAllAccessByGroupSuccess({
        payload: data,
        data: data.data,
      }))
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getAllAccessByGroupFailure(data))
    }
  } catch (error) {
    yield put(getAllAccessByGroupFailure(error))
  }
}

function* workerInsertOrUpdateAccessDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_ACCESS_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(insertOrUpdateAccessSuccess({
        payload: data,
        data: data.data,
      }))
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(insertOrUpdateAccessFailure(data))
    }
  } catch (error) {
    yield put(insertOrUpdateAccessFailure(error))
  }
}

function* workerDeleteAccessDetails(action) {
  const request = action.data;
  try {
    const uri = `${ConfigAPI.DELETE_ACCESS_URL}?accessId=${request}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(deleteAccessSuccess({
        payload: data,
        data: data.data,
      }))
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(deleteAccessFailure(data))
    }
  } catch (error) {
    yield put(deleteAccessFailure(error))
  }
}

function* workerGetAllUserDropDownDetails(action) {
  try {
    const uri = `${ConfigAPI.GET_USER_DROP_DOWN_URL}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      // toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getAllUserDropDownSuccess({
        payload: data,
        data: data.data,
      }))
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getAllUserDropDownFailure(data))
    }
  } catch (error) {
    yield put(getAllUserDropDownFailure(error))
  }
}

function* workerInsertOrUpdateCreateUserDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_CREATE_USER_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(insertOrUpdateCreateUserSuccess({
        payload: data,
        data: data.data,
      }))
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(insertOrUpdateCreateUserFailure(data))
    }
  } catch (error) {
    yield put(insertOrUpdateCreateUserFailure(error))
  }
}

function* workerGetRollAccessMappingDropDownDetails(action) {
  try {
    const uri = `${ConfigAPI.GET_ROLE_ACCESS_MAPPING_URL}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      // toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getRollAccessMappingDropDownSuccess({
        payload: data,
        data: data.data,
      }))
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getRollAccessMappingDropDownFailure(data))
    }
  } catch (error) {
    yield put(getRollAccessMappingDropDownFailure(error))
  }
}

function* workerGetRoleAccessMappingDetails(action) {
  const { sort, page, search, OrderByColumnName } = action.data;
  try {
    const uri = `${ConfigAPI.ROLE_ACCESS_MAPPING_LIST_URL}?OrderByColumnName=${OrderByColumnName}&PageNumber=${page}&SortDirection=${sort}&GridFilterKey=${search}`;
    const { data } = yield call(baseAPI.get, uri);
    if (data.httpStatusCode === 200) {
      // toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getRoleAccessMappingSuccess({
        payload: data,
        data: data.data,
      }))
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getRoleAccessMappingFailure(data))
    }
  } catch (error) {
    yield put(getRoleAccessMappingFailure(error))
  }
}

function* workerInsertOrUpdateRoleAccessMappingDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INSERT_OR_UPDATE_ROLE_ACCESS_MAPPING_URL;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(insertOrUpdateRoleAccessMappingSuccess({
        payload: data,
        data: data.data,
      }))
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(insertOrUpdateRoleAccessMappingFailure(data))
    }
  } catch (error) {
    yield put(insertOrUpdateRoleAccessMappingFailure(error))
  }
}

function* UserManagementSaga() {
  yield takeEvery(UserManagementActionTypes.GET_ALL_ROLE_REQUEST, workerGetAllRoleDetails);
  yield takeEvery(UserManagementActionTypes.INSERT_OR_UPDATE_ROLE_REQUEST, workerInsertOrUpdateRoleDetails);
  yield takeEvery(UserManagementActionTypes.DELETE_ROLE_REQUEST, workerDeleteRoleDetails);
  yield takeEvery(UserManagementActionTypes.GET_ALL_CREATE_USER_REQUEST, workerGetAllCreateUser);
  yield takeEvery(UserManagementActionTypes.GET_ALL_ACCESS_REQUEST, workerGetAllAccessDetails);
  yield takeEvery(UserManagementActionTypes.GET_ACCESS_BY_GROUP_REQUEST, workerGetAllAccessByGroupDetails);
  yield takeEvery(UserManagementActionTypes.INSERT_OR_UPDATE_ACCESS_REQUEST, workerInsertOrUpdateAccessDetails);
  yield takeEvery(UserManagementActionTypes.DELETE_ACCESS_REQUEST, workerDeleteAccessDetails);
  yield takeEvery(UserManagementActionTypes.GET_ALL_USER_DROP_DOWN_REQUEST, workerGetAllUserDropDownDetails);
  yield takeEvery(UserManagementActionTypes.INSERT_OR_UPDATE_CREATE_USER_REQUEST, workerInsertOrUpdateCreateUserDetails);
  yield takeEvery(UserManagementActionTypes.GET_ROLE_ACCESS_MAPPING_DROP_DOWN_REQUEST, workerGetRollAccessMappingDropDownDetails);
  yield takeEvery(UserManagementActionTypes.INSERT_OR_UPDATE_ROLE_ACCESS_MAPPING_REQUEST, workerInsertOrUpdateRoleAccessMappingDetails);
  yield takeEvery(UserManagementActionTypes.GET_ROLE_ACCESS_MAPPING_REQUEST, workerGetRoleAccessMappingDetails);
}

export default UserManagementSaga;
