export const CodeBuilderActionTypes = {
  INIT_GET_CODEBUILDER_DETAILS_MODULE: "INIT_GET_CODEBUILDER_DETAILS_MODULE",
  UPDATE_INPUT_BOOLEAN_REQUEST: "UPDATE_INPUT_BOOLEAN_REQUEST",
  UPDATE_INPUT_BOOLEAN_SUCCESS: "UPDATE_INPUT_BOOLEAN_SUCCESS",
  UPDATE_INPUT_BOOLEAN_FAILED: "UPDATE_INPUT_BOOLEAN_FAILED",

  GET_MODIFIDED_COMBINATION_REQUEST: "GET_MODIFIDED_COMBINATION_REQUEST",
  GET_MODIFIDED_COMBINATION_SUCCESS: "GET_MODIFIDED_COMBINATION_SUCCESS",
  GET_MODIFIDED_COMBINATION_FAILED: "GET_MODIFIDED_COMBINATION_FAILED",

  GET_BAU_MASTER_DATA_REQUEST: "GET_BAU_MASTER_DATA_REQUEST",
  GET_BAU_MASTER_DATA_SUCCESS: "GET_BAU_MASTER_DATA_SUCCESS",
  GET_BAU_MASTER_DATA_FAILED: "GET_BAU_MASTER_DATA_FAILED",

  GET_SANCHECK_OLDCODE_REQUEST: "GET_SANCHECK_OLDCODE_REQUEST",
  GET_SANCHECK_OLDCODE_SUCCESS: "GET_SANCHECK_OLDCODE_SUCCESS",
  GET_SANCHECK_OLDCODE_FAILED: "GET_SANCHECK_OLDCODE_FAILED",

  GET_SANCHECK_GET_VARIENT_REQUEST: "GET_SANCHECK_GET_VARIENT_REQUEST",
  GET_SANCHECK_GET_VARIENT_SUCCESS: "GET_SANCHECK_GET_VARIENT_SUCCESS",
  GET_SANCHECK_GET_VARIENT_FAILED: "GET_SANCHECK_GET_VARIENT_FAILED",

  SANCHECK_EXCELEXPORT_REQUEST: "SANCHECK_EXCELEXPORT_REQUEST",
  SANCHECK_EXCELEXPORT_SUCCESS: "SANCHECK_EXCELEXPORT_SUCCESS",
  SANCHECK_EXCELEXPORT_FAILED: "SANCHECK_EXCELEXPORT_FAILED",

  GET_CODES_DROPDOWN_REQUEST: "GET_CODES_DROPDOWN_REQUEST",
  GET_CODES_DROPDOWN_SUCCESS: "GET_CODES_DROPDOWN_SUCCESS",
  GET_CODES_DROPDOWN_FAILED: "GET_CODES_DROPDOWN_FAILED",

  GET_SELECTED_CODE_TRUTHTABLE_REQUEST: "GET_SELECTED_CODE_TRUTHTABLE_REQUEST",
  GET_SELECTED_CODE_TRUTHTABLE_SUCCESS: "GET_SELECTED_CODE_TRUTHTABLE_SUCCESS",
  GET_SELECTED_CODE_TRUTHTABLE_FAILED: "GET_SELECTED_CODE_TRUTHTABLE_FAILED",

  GET_BOOLEANLOGIC_LOGICCHECK_REQUEST: "GET_BOOLEANLOGIC_LOGICCHECK_REQUEST",
  GET_BOOLEANLOGIC_LOGICCHECK_SUCCESS: "GET_BOOLEANLOGIC_LOGICCHECK_SUCCESS",
  GET_BOOLEANLOGIC_LOGICCHECK_FAILED: "GET_BOOLEANLOGIC_LOGICCHECK_FAILED",

  GET_BOOLEANLOGIC_CODEGENERATION_REQUEST: "GET_BOOLEANLOGIC_CODEGENERATION_REQUEST",
  GET_BOOLEANLOGIC_CODEGENERATION_SUCCESS: "GET_BOOLEANLOGIC_CODEGENERATION_SUCCESS",
  GET_BOOLEANLOGIC_CODEGENERATION_FAILED: "GET_BOOLEANLOGIC_CODEGENERATION_FAILED",

  GET_CODEBUILDER_VEHICLE_GROUP_REQUEST: "GET_CODEBUILDER_VEHICLE_GROUP_REQUEST",
  GET_CODEBUILDER_VEHICLE_GROUP_SUCCESS: "GET_CODEBUILDER_VEHICLE_GROUP_SUCCESS",
  GET_CODEBUILDER_VEHICLE_GROUP_FAILED: "GET_CODEBUILDER_VEHICLE_GROUP_FAILED",
};
