import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Label,
  Card,
} from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import Loader from "../Loader/Loader";
import { getBauMasterDataAction, initCodeBuilderModule, getSanCheckOldcodeAction, getSanCheckVarientAction, sanCheckExcelExportAction } from "../../store/actions";
import BreadCrumb from "../../Components/Common/BreadCrumb";

// To be removed

type ModelType = {
  label: string;
  value: string;
};

type VechicleType = {
  label: string;
  value: string;

};

type TableDataType = {
  designerInputs: {
    plVnumber: string;
    oldCode: string;
    newCode: string;
  }[];
  newSteering: {
    id: number;
    value: string;
    isSelected: boolean;
  }[];
  oldSteering: {
    id: number;
    value: string;
    isSelected: boolean;
  }[];
};

const SanCheck = (props: any) => {

  document.title = "San check";
  const location = useLocation();
  const { data, vehicleGroupId } = location.state || { data: [] };
  console.log(data, vehicleGroupId, "vehicleGroupIdvehicleGroupId");
  
  const { getBauMasterData, getSancheckOldcodeData, getSancheckVarientData, sanCheckExcelExport, getModifidedCombination, getBooleanModifiedCombination, loading } = props;
  const [selectedModels, setSelectedModels] = useState<ModelType[] | null>(null);
  const [selectedVechicle, setSelectedVechicle] = useState<VechicleType[] | null>(null);
  const [EncodeDecodeCode, setEncodeDecodeCode] = useState<string | null>(data);
  const errors: { [key: string]: string } = {};
  const [formValidation, setFormValiodation] = useState<{ [key: string]: string }>({});
  const [sanCheckOldCode, setSanCheckOldCode] = useState<any>(null);
  const [designerInputs, setDesignerInputs] = useState<TableDataType['designerInputs']>(getSancheckOldcodeData?.designerInputs);
  const [selectedOldSteering, setSelectedOldSteering] = useState<TableDataType['oldSteering'][number] | null>([]);
  const [selectedNewSteering, setSelectedNewSteering] = useState<TableDataType['newSteering'][number] | null>([]);
  const [getVehicleModel, setGetVehicleModel] = useState<any>([]);
  const [getVehicleType, setGetVehicleType] = useState<any>([]);
  const [modelsList, setModelsList] = useState<any>({});
  const [modelValues, setModelValues] = useState<any>({});
  const [endResultData, setEndResultData] = useState<any>([]);

  const [vehicleList, setVehicleList] = useState<any>({});
  const [vehicleValues, setVehicleValues] = useState<any>({});
  const [excelUrl, setExcelUrl] = useState<any>([]);
  

  // to be deleted
  const [showtable, setShowtable] = useState(false);

  const Models = getVehicleModel?.map((item: any) => (
    {
      label: item?.value,
      value: item?.value,
      id: item?.id,
      isSelected: item?.isSelected
    }
  )
  ) || [];

  const Vechicle = getVehicleType?.map((item: any) => (
    {
      label: item?.value,
      value: item?.value,
      id: item?.id,
      isSelected: item?.isSelected
    }
  )
  ) || [];

  const handleModels = (selectedOptions: any) => {
    setSelectedModels(selectedOptions);
    const transformedData = selectedOptions.map(option => ({
      id: option?.id,
      value: option?.value,
      isSelected: option?.isSelected
    }));
    setModelsList(transformedData);
    const modelValue = selectedOptions.map(option => ({
      value: option?.value
    }));
    setModelValues(modelValue)
  };

  const handleVechicle = (selectedOptions: any) => {
    setSelectedVechicle(selectedOptions);
    const transformedData = selectedOptions.map(option => ({
      id: option?.id,
      value: option?.value,
      isSelected: option?.isSelected
    }));
    setVehicleList(transformedData)
    const vehicleValue = selectedOptions.map(option => ({
      value: option?.value,
    }));
    setVehicleValues(vehicleValue)
  };

  const handleEncode_Decode_code = (value: string) => {
    setEncodeDecodeCode(value);
  };

  // useEffect(() => {
  //   setEncodeDecodeCode(getBooleanModifiedCombination?.reformationBooleanLogic || getModifidedCombination?.reformationBooleanLogic)
  // },[getModifidedCombination, getBooleanModifiedCombination])

  const handlesearch = () => {
    if (!selectedModels?.length) {
      errors.models = "Please select the models";
    }
    if (!selectedVechicle?.length) {
      errors.Vechicle = "Please select the vehicle type";
    }
    if (!EncodeDecodeCode?.length) {
      errors.Encode_Decode_code = "Please Insert Encode/Decoded Code ";
    }
    setFormValiodation(errors);

    if (
      (!!selectedModels && selectedModels?.length > 0) &&
      // (!!selectedVechicle && selectedVechicle?.length > 0) &&
      EncodeDecodeCode?.length != 0
    ) {
      props.getSanCheckOldcodeAction({ vehicleModel: modelsList, vehicleType: vehicleList, booleanLogic: EncodeDecodeCode });
      setShowtable(true);
    }
  };

  // const handleOldSteering = (e: any, index: number) => {
  //   setSelectedOldSteering(e);
  // };

  const handleOldSteering = (selectedOptions: any, index: number) => {
    const currentOptions = [...selectedOldSteering];
    if (Array.isArray(currentOptions[index])) {
      currentOptions[index] = [...currentOptions[index], ...selectedOptions];
    } else {
      currentOptions[index] = selectedOptions;
    }
    setSelectedOldSteering(currentOptions);
  };

  // const handleNewSteering = (selectedOptions: any, index: number) => {
  //   const data =[selectedOptions]
  //   setSelectedNewSteering([...data]);
  // };

  const handleNewSteering = (selectedOptions: any, index: number) => {
    const currentOptions = [...selectedNewSteering];
    if (Array.isArray(currentOptions[index])) {
      currentOptions[index] = [...currentOptions[index], ...selectedOptions];
    } else {
      currentOptions[index] = selectedOptions;
    }
    setSelectedNewSteering(currentOptions);
  };

  const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const updatedDesignerInputs = [...designerInputs];
    updatedDesignerInputs[index][name] = value;
    setDesignerInputs(updatedDesignerInputs);
  };

  const handleVarient = () => {
    // console.log(designerInputs, "designerInputs");
    // console.log(selectedOldSteering, "selectedOldSteering");
    // console.log(selectedNewSteering, "selectedNewSteering");
    // const vehicleTypeArray = vehicleValues?.map(item => item?.value);
    const vehicleModelArray = modelValues?.map(item => item?.value);
    const oldStee = selectedOldSteering?.map(item => ({
      id: item?.id,
      isSelected: item?.isSelected,
      value: item?.value
    }));
    const newStee = selectedNewSteering?.map(item => ({
      id: item?.id,
      isSelected: item?.isSelected,
      value: item?.value
    }));
    props.getSanCheckVarientAction({ designerInputs, oldSteering: oldStee, newSteering: newStee, vehicleModel: vehicleModelArray, vehicleGroup: getBauMasterData?.vehicleGroup });
  };

  useEffect(() => {
    if (sanCheckExcelExport.httpStatusCode == 200) {
        window.location.href = excelUrl
    }
    if (
      sanCheckExcelExport &&
      sanCheckExcelExport?.dataList &&
      sanCheckExcelExport?.dataList?.length > 0 &&
      sanCheckExcelExport?.dataList[0].getExcelFilePath
    ) {
        const excelData = sanCheckExcelExport?.dataList[0].getExcelFilePath;
        setExcelUrl(excelData);
    }
}, [sanCheckExcelExport, excelUrl]);

  const handleExcelExport = () => {
    props.sanCheckExcelExportAction(getSancheckVarientData?.map((datas) => ({
      VariantNumber: datas?.variantNumber,
      Type: datas?.type,
      Description: datas?.description,
      MajorCodeList: datas?.majorCodeList,
      GeneratedCode: datas?.generatedCode,
      Feasible: datas?.feasible,
      FirstSupply: datas?.firstSupply,
      Supplied: datas?.supplied,
      OrderNumber: datas?.orderNumber,
      Country: datas?.country,
      Model1: datas?.model1,
      Cumulative: datas?.cumulative,
      ActualChange: datas?.actualChange,
      PLVnumber: datas?.plVnumber,
      MatchingOldVariantCode: datas?.matchingOldVariantCode,
      MatchingNewVariantCode: datas?.matchingNewVariantCode
    })));
  }

  useEffect(() => {
    props.getBauMasterDataAction(vehicleGroupId)
  }, []);

  useEffect(() => {
    if (getBauMasterData) {
      setGetVehicleModel(getBauMasterData?.vehicleModel);
      setGetVehicleType(getBauMasterData?.vehicleType);
    }
  }, [getBauMasterData]);

  useEffect(() => {
    if (getSancheckOldcodeData) {
      setSanCheckOldCode(getSancheckOldcodeData);
      setDesignerInputs(getSancheckOldcodeData?.designerInputs);
    }
  }, [getSancheckOldcodeData, designerInputs]);

  useEffect(() => {
    setEndResultData(getSancheckVarientData)
  }, [getSancheckVarientData])

  // useEffect(() => {
  //   props.initCodeBuilderModule()
  // }, [])


  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="San Check" pageTitle="Code Builder" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">San Check</h4>
                </CardHeader>

                <CardBody>
                  <div>
                    <div>
                      <Row>
                      <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-groups"
                              className="form-label text-muted"
                            >
                              Vehicle Group
                            </Label>
                            {/* <Select
                              value={selectedVechicle}
                              isMulti={true}
                              // searchable
                              onChange={(e) => {
                                handleVechicle(e);
                              }}
                              styles={{
                                valueContainer: (base) => ({ ...base, maxHeight: 50, overflowY: 'auto' }),
                              }}
                              options={Vechicle}
                            /> */}
                            {/* <span className="text-validation">
                              {formValidation?.Vechicle}
                            </span> */}
                             <Input
                               disabled
                                className="form-control"
                                type="text"
                                value={getBauMasterData?.vehicleGroup ?? ''}
                              />
                          </div>
                        </Col>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-default"
                              className="form-label text-muted"
                            >
                              Vehicle Model
                            </Label>
                            <Select
                              value={selectedModels}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              onChange={(e) => {
                                handleModels(e);
                              }}
                              styles={{
                                valueContainer: (base) => ({ ...base, maxHeight: 50, overflowY: 'auto' }),
                              }}
                              options={Models}
                            />
                            <span className="text-validation">
                              {formValidation.models}
                            </span>
                          </div>
                        </Col>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="formFile"
                              className="form-label text-muted"
                            >
                              Encode/Decoded Code
                            </Label>
                            <div>
                              <Input
                                className="form-control"
                                type="text"
                                onChange={(e) => {
                                  handleEncode_Decode_code(e.target.value);
                                }}
                                value={EncodeDecodeCode ?? ''}
                              />
                            </div>
                            <span className="text-validation">
                              {formValidation.Encode_Decode_code}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row>
                        <div>
                          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <Button
                              className="btn btn-primary btn-custom-blue"
                              type="button"
                              color="light"
                              onClick={handlesearch}
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </Row>
                    </div>
                    <div></div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {showtable && (
            <Row>
              <Col xl={12}>
                <Card>
                  <CardHeader className="align-items-center d-flex card-bg">
                    <h4 className="card-title mb-0 flex-grow-1">
                      Designer Input
                    </h4>
                    <div className="flex-shrink-0">
                      <div className="form-check form-switch form-switch-right form-switch-md">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                          <Button
                            className="btn btn-primary btn-custom-blue"
                            type="button"
                            color="light"
                            onClick={handleVarient}
                          >
                            Get Varient
                          </Button>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="card-padding">
                    {showtable && (
                      <div className="live-preview">
                        <div
                          className="table-responsive table-card"
                          style={{ maxHeight: "700px", minHeight: "300px" }}
                        >
                          <table className="table align-middle table-striped mb-0">
                            <thead className="table-light">
                              <tr>
                                <th scope="col">PLV Number</th>
                                <th scope="col">Old-Code</th>
                                <th scope="col">Old-Steering</th>
                                <th scope="col">New_Code</th>
                                <th scope="col">New-Steering</th>
                              </tr>
                            </thead>
                            <tbody>
                              {designerInputs?.map((data, index) => (
                                <tr key={index}>
                                  <td>{data?.plVnumber}</td>
                                  <td>{data?.oldCode}</td>
                                  <td>
                                    <Select
                                      value={sanCheckOldCode?.oldSteering?.find(
                                        (option) =>
                                          option?.value === data?.oldCode
                                      )}
                                      onChange={(e) =>
                                        handleOldSteering(e, index)
                                      }
                                      options={sanCheckOldCode?.oldSteering?.map((option) => ({
                                        id: option?.id,
                                        value: option?.value,
                                        label: option?.value,
                                        isSelected: option?.isSelected
                                      }))}
                                    />
                                  </td>
                                  <td>
                                    <Input
                                      className="form-control"
                                      type="text"
                                      name="newCode"
                                      value={data?.newCode}
                                      onChange={(e) =>
                                        handleInputChange(index, e)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Select
                                      value={sanCheckOldCode?.newSteering?.find(
                                        (option) =>
                                          option?.value === data.newCode
                                      )}
                                      onChange={(e) =>
                                        handleNewSteering(e, index)
                                      }
                                      options={sanCheckOldCode?.newSteering?.map(
                                        (option) => ({
                                          id: option?.id,
                                          value: option?.value,
                                          label: option?.value,
                                          isSelected: option?.isSelected
                                        })
                                      )}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>

              {endResultData?.length > 0 &&
                <Col xl={12}>
                  <Card>
                    <CardHeader className="align-items-center d-flex card-bg">
                      <h4 className="card-title mb-0 flex-grow-1">
                        End result
                      </h4>
                      <div className="flex-shrink-0">
                        <div className="form-check form-switch form-switch-right form-switch-md">
                          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <Button
                              className="btn btn-primary btn-custom-blue"
                              type="button"
                              color="light"
                              onClick={() => handleExcelExport()}
                            >
                              Excel Export
                            </Button>
                          </div>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="card-padding">
                      {endResultData?.length > 0 && (
                        <div className="live-preview">
                          <div
                            className="table-responsive table-card"
                            style={{ maxHeight: "700px", minHeight: "300px" }}
                          >
                            <table className="table align-middle table-striped mb-0">
                              <thead className="table-light">
                                <tr>
                                  <th scope="col">Variant Number</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Description</th>
                                  <th scope="col">Country</th>
                                  <th scope="col">Modul 1</th>
                                  <th className='table-width' scope="col">Cumulative</th>
                                  <th scope="col">Actual Change</th>
                                  <th scope="col">PLV Number</th>
                                  <th scope="col">Matching Old Variant -Code </th>
                                  <th scope="col">Matching NewVariant -Code</th>
                                </tr>
                              </thead>
                              <tbody>
                                {endResultData?.length > 0 && endResultData?.map((data, index) => (
                                  <tr key={index}>
                                    <td>{data?.variantNumber}</td>
                                    <td>{data?.type}</td>
                                    <td>{data?.description}</td>
                                    <td>{data?.country}</td>
                                    <td>{data?.model1}</td>
                                    <td className='table-width' >{data?.cumulative}</td>
                                    <td>{data?.actualChange}</td>
                                    <td>{data?.plVnumber}</td>
                                    <td>{data?.matchingOldVariantCode ?data?.matchingOldVariantCode : "-"}</td>
                                    <td>{data?.matchingNewVariantCode? data?.matchingNewVariantCode : "-"}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              }
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  getBauMasterData: state?.CodeBuilderReducer?.getBauMasterData,
  getSancheckOldcodeData: state?.CodeBuilderReducer?.getSancheckOldcodeData,
  getSancheckVarientData: state?.CodeBuilderReducer?.getSancheckVarientData,
  sanCheckExcelExport: state?.CodeBuilderReducer?.sanCheckExcelExport,
  getModifidedCombination: state?.CodeBuilderReducer?.getModifidedCombination,
  getBooleanModifiedCombination: state?.CodeBuilderReducer?.getBooleanModifiedCombination,
  loading: state?.CodeBuilderReducer?.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  getBauMasterDataAction: (data: any) => dispatch(getBauMasterDataAction(data)),
  getSanCheckOldcodeAction: (data: any) => dispatch(getSanCheckOldcodeAction(data)),
  getSanCheckVarientAction: (data: any) => dispatch(getSanCheckVarientAction(data)),
  sanCheckExcelExportAction: (data: any) => dispatch(sanCheckExcelExportAction(data)),
  initCodeBuilderModule: (data: any) => dispatch(initCodeBuilderModule(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(SanCheck);
